import { ApiHttpService } from '../../services'
export const UnitOfMeasurementModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        data: {
            columns: [],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},

        unitOfMeasurements: [],


    },
    actions: {

        async getUnitOfMeasurements({commit},params) {
            return await new Promise((resolve,reject) => {
                ApiHttpService.getRequest('unit-of-measurement', params).then((response) => {
                    console.log("response unit of measurements",response.data)
                    commit('setUnitOfMeasurements',response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

    },
    mutations: {
        setUnitOfMeasurements: (state, payload) => {
            state.unitOfMeasurements = payload
        }

    },
    getters: {
        unitOfMeasurements: state => state.unitOfMeasurements,
    }
}


