/* eslint-disable no-prototype-builtins */
<template>
  <div>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <XWindow ref="window" :title="`Inventory Transfer Request (${form.status.toUpperCase()})${(form.series != null || form.series != '' ? ' - ' + form.series : null)}`" windowClass="height-auto bts-window" scrollClass='collection height-auto' :height="height" :width="width" :formStatus="formStatus" @dialog-state="dialogState" :group="(group + 10)" :isMaximized="isMaximized" @onResize="onResize">

        <template #left-button>
              <!-- <template v-if="formLoading || getLoading || onPasteLoading">
                <mdb-btn color="elegant" size="sm" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </mdb-btn>
              </template> -->

          

              <template v-if="formLoading || getLoading || postLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        
                        <template v-if="formStatus == 'update'">
                          <mdb-btn color="elegant" icon="printer" size="sm" @click="fnPrintDoc('/inventory-transfer-request/print/'+form.series)"><i class="el-icon-printer"></i> PRINT</mdb-btn>
                          <mdb-btn color="elegant" size="sm" @click="openHistoryDialog"><i class="el-icon-save text-white cursor-pointer"></i> History</mdb-btn>
                        </template>
                        <template v-if="form.status == 'draft'">
                          <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('draft'))"><i class="el-icon-success"></i> Save as draft</mdb-btn>
                          <mdb-btn color="elegant" size="sm"  @click="passes(onSubmitForm('for-approval'))"><i class="el-icon-success"></i> Save as for approval</mdb-btn>
                        </template>
                        <template v-if="form.status == 'for-approval'">
                          <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('draft'))"><i class="el-icon-success"></i> Save as draft</mdb-btn>
                        </template>
                        <template v-if="form.status == 'for-approval' && isAllowedToApproved">
                          <mdb-btn color="elegant" size="sm"  @click="passes(onSubmitForm('for-approval'))"><i class="el-icon-circle-check"></i> Save</mdb-btn>
                          <mdb-btn color="success" size="sm" @click="passes(onSubmitForm('approved'))"><i class="el-icon-check"></i> Approved</mdb-btn>
                          <mdb-btn color="danger" size="sm" @click="passes(onSubmitForm('rejected'))"><i class="el-icon-close"></i> Reject</mdb-btn>
                        </template>
                        <template v-if="formStatus == 'update' && hasLocationToWarehouse">
                          <mdb-btn color="elegant" icon="printer" size="sm" @click="fnPrintDoc('/inventory-transfer-request/print-label?items='+hasLocationToWarehouse)"><i class="el-icon-printer"></i> PRINT LABEL</mdb-btn>
                        </template>
                        <template v-if="showMakeInventoryTransfer">
             
                            <template v-if="active == 2">

                            

                              <template v-if="form.inventory_transfer_form.items.length > 0">
                              <mdb-btn color="elegant" size="sm" @click="makeInventoryTransfer">Make Inventory Transfer</mdb-btn>
                            </template>
                          </template>
                        </template>
                      </template>
     
                      
          </template>

            <template #right-button>
            </template>
            <mdb-tabs class="table-tabs bts-form-tabs mb-0 form mt-1" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">
             
                <mdb-row class="tab-row">
                  <mdb-col col="6" class="tab-col">

                   
                    <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Document Date" placeholder="Document Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>
                    <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="SHOWROOM" v-model="form.showroom.id" :reduce="label => label.value" :reduceLabel="'label'" :options="showroomLocation" :rules="'required'" :disabled="(form.status != 'draft' ? true : false)"></ThatsMultiSelect>
                    
                    <!--   <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Document Name" vid="doc_name" name="doc_name" v-model="form.doc_name" size="sm" :disabled="false" :rules="'required'"/> -->
                  </mdb-col>   
                  <mdb-col col="6" class="tab-col">
                    <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Requested By" vid="requested_by" name="requested_by" v-model="form.requested_by.name" size="sm" :disabled="true" :rules="'required'"/>
                    
                    <template v-if="form.status == 'approved'">
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Approved By" vid="approved_by" name="approved_by" v-model="form.approved_by.text" size="sm" :disabled="true" :rules="'required'"/>
            
                    </template>
                    <template v-else>
                      <ThatsMultiSelect :key="eventKeyCounter" :multiple="true"  labelClass="col-md-4" fieldClass="col-md-8 pl-0" inputClass="select-multiple" label="Approver" v-model="form.approver" :reduce="name => name" :reduceLabel="'name'" :options="documentApprover"  :rules="'required'" :filterable="false" :clearable="true" :selectable="(option) => true" :disabled="(form.status != 'draft' ? true : false)"></ThatsMultiSelect>                      
                    </template>
                    
                   
                  </mdb-col>
                </mdb-row>
                <div ref="spreadsheet" class="bts-window-form mt-1" v-bind:style="{'height': jexcelContainer.height}"></div>
              </template>
              
              <template :slot="'remarks-slot'">
                <mdb-row class="tab-row mt-1 mb-1">
                  <mdb-col col="6" class="tab-col">
                    <ThatsTextarea tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Remarks" vid="description" name="description" v-model="form.description" size="sm"/>
                  </mdb-col>
                </mdb-row>
              </template>


              <template :slot="'inventory-transfer-slot'" >
                <div class="window-inner-container mt-1">

             
                  <mdb-row class="tab-row">
                    <mdb-col col="12" class="tab-col">

                      <template v-if="showMakeInventoryTransfer">
             
                          <template v-if="active == 2">
                                    
                            <mdb-row class="tab-row">
                              <mdb-col col="6" class="tab-col">
                               
                                <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Document Date" placeholder="Document Date" vid="date" name="date" v-model="form.inventory_transfer_form.date" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>
                                <ThatsMultiSelect :show_error="true" @input="onInputLocationFrom"  @select="onSelectLocation($event,'from')" :multiple="false" :clearable="true"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="FROM" v-model="form.inventory_transfer_form.showroomFrom.value" :reduce="label => label.value" :reduceLabel="'label'" :options="inventoryTransferOptionsFrom" :rules="'required'"></ThatsMultiSelect>
                                <ThatsMultiSelect :show_error="true" @input="onInputLocationTo"  @select="onSelectLocation($event,'to')" :multiple="false" :clearable="true"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="TO" v-model="form.inventory_transfer_form.showroomTo.value" :reduce="label => label.value" :reduceLabel="'label'" :options="inventoryTransferOptionsTo" :rules="'required'"></ThatsMultiSelect>
                              
                              </mdb-col>   
                              <mdb-col col="6" class="tab-col">
                                
                                <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Prepared By" vid="inventory_transfer_form_prepared_by" name="inventory_transfer_form_prepared_by" v-model="form.inventory_transfer_form.prepared_by.name" size="sm" :disabled="true" :rules="'required'"/>
                                <ThatsMultiSelect :show_error="true" :multiple="false" :clearable="true"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Receiver" v-model="form.inventory_transfer_form.receiver_id" :reduce="label => label.value" :reduceLabel="'label'" :options="documentReceiver" :rules="'required'"></ThatsMultiSelect>
                                <ThatsTextarea tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Remarks" vid="description" name="description" :rows="1" v-model="form.inventory_transfer_form.description" size="sm"/>
                              </mdb-col>
                            </mdb-row>
                         
                              <el-table ref="deliveryItemsTable" border :key="eventKeyCounter" stripe :data="inventoryTransferItems" class="input-table mt-2 custom-element-table " style="width: 100%" @selection-change="handleSelectionChange" :row-key="(row) => row.id">
                                    <el-table-column type="index" width="50" label="SQ" align="center"> </el-table-column>

                                    <el-table-column type="selection" width="35" :reserve-selection="true" :selectable="canSelectRow"> </el-table-column>

                                    <el-table-column label="SKU" :width="150">
                                      <template slot-scope="scope">
                                      {{ scope.row.series }}
                                      </template>
                                    </el-table-column>
                                    <el-table-column label="Image" width="90">
                                          <template slot-scope="scope">
                                            <img :src="scope.row.photo" style="width:90px" />
                                          </template>
                                        </el-table-column>
                                    <el-table-column label="Item Name" :width="350">
                                      <template slot-scope="scope">
                                        {{scope.row.name}}
                                      </template>
                                    </el-table-column>
                                      <el-table-column label="Requested" :width="120" align="center">
                                        <template slot-scope="scope">
                                          {{ scope.row.quantity }}
                                        </template>
                                      </el-table-column>
                                      <el-table-column label="From" :width="100">
                                        <template slot-scope="scope">
                                          {{ scope.row.locationFrom }}
                                        </template>
                                      </el-table-column>

                                      <el-table-column label="To" :width="100">
                                        <template slot-scope="scope">
                                          {{ scope.row.locationTo }}
                                        </template>
                                      </el-table-column>

                                      <el-table-column label="Remaining Qty" :width="150">
                                        <template slot-scope="scope">
                                          <input type="number" min="1" :max="scope.row.max" v-on:change="quantityChange(scope.row,$event)" class="form-control grid-input inventory-form-input" v-model="scope.row.remaining" :disabled="disableInput(scope.row)" />
                                        </template>
                                      </el-table-column>
                                    
                                      <el-table-column label="Remarks" align = "left" width="250">
                                        <template slot-scope="scope">
                                          <textarea v-model="scope.row.description" :disabled="!canSelectRow" class="w-100"></textarea>
                                        </template>
                                      </el-table-column>
                                  </el-table>

                                  </template>
                    </template>
      
                    <el-table ref="itemsTable" border stripe :data="form.inventory_transfer" class="input-table mt-2 custom-element-table" style="width: 100%">
                      
                      <el-table-column label="Series" width="200">
                            <template slot-scope="scope">
                              {{scope.row.series}}
                            </template>
                          </el-table-column>
                      <el-table-column label="Date" width="100">
                            <template slot-scope="scope">
                              {{scope.row.date}}
                            </template>
                          </el-table-column>


                          <el-table-column label="Receiver">
                            <template slot-scope="scope">
                              {{scope.row.receiver.name}}
                            </template>
                          </el-table-column>

                      
                      <el-table-column label="Status" width="250">
                        <template slot-scope="scope">
                          {{scope.row.status_text}}
                        </template>
                      </el-table-column>
                      <el-table-column label="Remarks">
                        <template slot-scope="scope">
                          {{scope.row.description}}
                        </template>
                      </el-table-column>
                      <el-table-column width="80" align="center">
                        <template slot-scope="scope">

                          <el-dropdown :hide-on-click="true" trigger="click">
                            <el-button size="mini"><i class="el-icon-more el-icon--center"></i></el-button>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item @click.native="fnPrintDoc('/inventory-transfer/print/'+scope.row.series)"><i class="el-icon-printer text-info cursor-pointer"></i> PRINT</el-dropdown-item>
                              

                              <template v-if="['open','open-updated'].includes(scope.row.status)">
                                <el-dropdown-item @click.native="receiveInventoryTransfer(scope.row,'receive')"><i class="el-icon-success text-success cursor-pointer"></i> RECEIVE</el-dropdown-item>
                              </template>

                              <template v-if="['received-with-discrepancy'].includes(scope.row.status) && showMakeInventoryTransfer">
                                <el-dropdown-item @click.native="receiveInventoryTransfer(scope.row,'adjustment')"><i class="el-icon-success text-success cursor-pointer"></i> ADJUSTMENT</el-dropdown-item>
                              </template>

                              <template v-if="['received-with-discrepancy','received'].includes(scope.row.status)">
                                <el-dropdown-item @click.native="receiveInventoryTransfer(scope.row,'view')"><i class="el-icon-success text-success cursor-pointer"></i> VIEW</el-dropdown-item>
                              </template>

                              <template v-if="scope.row.status == 'open' && showMakeInventoryTransfer">
                                <el-dropdown-item @click.native="cancelRecord(scope.row)"><i class="el-icon-error text-danger cursor-pointer"></i> CANCEL</el-dropdown-item>
                              </template>

                            </el-dropdown-menu>
                          </el-dropdown>

                         
                    
                         <!--  <template v-if="scope.row.status !== 'cancelled'">
      
                           <mdb-btn class="m-0" @click="fnPrintDoc('/inventory-transfer/print/'+scope.row.series)" color="elegant" size="sm">Print</mdb-btn>
                           <mdb-btn class="m-0" @click="cancelInventoryTransfer(scope.row.series)" color="elegant" size="sm">Cancel</mdb-btn>
                          </template> -->
                        </template>
                      </el-table-column>

                      <el-table-column></el-table-column>
                    </el-table>

                  </mdb-col>
                </mdb-row>
                  </div>
              </template>
            </mdb-tabs>
            
          </XWindow>
    
  </ValidationObserver>
  <InventoryTransferReceivingForm ref="inventoryTransferReceivingForm" @success="receivedSuccess"></InventoryTransferReceivingForm>

  <CoolLightBox 
            :items="gallery" 
            :index="index"
            :useZoomBar="true"
            @close="index = null">
            </CoolLightBox>
    <UploadFromDevice ref="uploadRef"></UploadFromDevice>
    <HistoryDialog ref="historyDialog"></HistoryDialog>

    <el-dialog
      title="SELECT OPTION"
      :visible.sync="labelModal"
      width="20%"
      center>

      <el-button plain @click="fnItemLabelOrigin('sold')" class="w-100  m-1">FROM INVOICE</el-button>
      <el-button plain @click="fnItemLabelOrigin('display')" class="w-100  m-1">FROM DISPLAY</el-button>

    </el-dialog>


    <el-dialog
      title="INVOICES"
      :visible.sync="labelModalFromInvoice"
      width="60%"
      center class="item-invoice-dialog">
      <ValidationObserver tag="form" ref="fromInvoiceObserver" v-slot="{passes}" @submit.prevent="confirmInvoices()">
      <el-table
        ref="multipleTable"
        :data="itemInvoices"
        style="width: 100%"
        @selection-change="handleInvoiceSelectionChange">
        <el-table-column
          type="selection"
          width="45">
        </el-table-column>
        <el-table-column
          label="Date"
          width="150">
          <template slot-scope="scope">{{ scope.row.date }}</template>
        </el-table-column>

        <el-table-column
          label="Invoice No."
          width="150">
          <template slot-scope="scope">{{ scope.row.invoice }}</template>
        </el-table-column>

        <el-table-column
          label="Customer">
          <template slot-scope="scope">{{ scope.row.customer }}</template>
        </el-table-column>`
        <el-table-column
          label="Touch Up?"
          width="150">
          <template slot-scope="scope">

            <el-select v-model="scope.row.touch_up" placeholder="Select" append-to-body>
              <el-option
                v-for="item in [{
                    value: 'Yes',
                    label: 'Yes'
                  }, {
                    value: 'No',
                    label: 'No'
                  }]"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>`

        <el-table-column
          label="EXPECTED DELIVERY DATE"
          width="230">
          <template slot-scope="scope">

            <ValidationProvider :vid="`expected_delivery_date_${scope.$index}`" :name="`expected_delivery_date_${scope.$index}`"  :rules="(checkIfRowSelected(scope.row.id) === true ? 'required' : '')" v-slot="{errors}">
              <div class="el-form-item m-0" v-bind:class="{'is-error': errors[0]}">
                <el-date-picker
                  v-model="scope.row.expected_delivery_date"
                  type="date"
                  placeholder="Pick a date">
                </el-date-picker>
              <div class="el-form-item__error" style="position: relative;top : initial;" v-if="errors[0]">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>

            
          </template>
        </el-table-column>`
        
      </el-table>
      
      <span slot="footer" class="dialog-footer text-left">
      <mdb-btn color="elegant" :disabled="selectedInvoices.length == 0 || itemInvoicesLoading" @click="passes(confirmInvoices)" size="sm" class="m-0">Confirm</mdb-btn>
      <mdb-btn @click="labelModalFromInvoice = false" color="grey" :disabled="itemInvoicesLoading" size="sm" class="m-0">Cancel</mdb-btn>
      </span>
    </ValidationObserver>
    </el-dialog>

    <el-dialog
      title="FROM DISPLAY"
      :visible.sync="displayModalFromDisplay"
       width="60%"
      center class="item-invoice-dialog">
      <ValidationObserver tag="form" ref="fromDisplayObserver" v-slot="{passes}" @submit.prevent="confirmDisplay()">
      
      <el-table
        ref="multipleTable"
        :data="pulloutItems"
        style="width: 100%">
        
        <el-table-column
          label="Item Code"
          width="200">
          <template slot-scope="scope">{{ scope.row.series }}</template>
        </el-table-column>

        <el-table-column
          label="Name"
          >
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
       
        <el-table-column
          label="LOCATION"
          width="120">
          <template slot-scope="scope">{{ scope.row.locationFrom }}</template>
        </el-table-column>
      
        <el-table-column
          label="Touch Up?"
          width="150">
          <template slot-scope="scope">
            
            <el-select v-model="scope.row.touch_up" placeholder="Select">
              <el-option
                v-for="item in [{
                    value: 'Yes',
                    label: 'Yes'
                  }, {
                    value: 'No',
                    label: 'No'
                  }]"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
           
          </template>
        </el-table-column>`

        <el-table-column
          label="PULL-OUT DATE"
          width="230">
          <template slot-scope="scope">
            <ValidationProvider vid="pullout_date" name="pullout_date" :rules="'required'" v-slot="{errors}">
              <div class="el-form-item m-0" v-bind:class="{'is-error': errors[0]}">
                  <el-date-picker
                  v-model="scope.row.pullout_date"
                  type="date"
                  placeholder="Pick a date">
                </el-date-picker>
                <div class="el-form-item__error" style="position: relative;top : initial;" v-if="errors[0]">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </template>
        </el-table-column>
        
      </el-table>
      
      <span slot="footer" class="dialog-footer text-left">
      <mdb-btn color="elegant"  @click="passes(confirmDisplay)" size="sm" class="m-0">Confirm</mdb-btn>
      <mdb-btn @click="displayModalFromDisplay = false" color="grey" size="sm" class="m-0">Cancel</mdb-btn>
      </span>
      </ValidationObserver> 
    </el-dialog>

  </div>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput, mdbContainer} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'



import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect, ThatsDatePicker, UploadFromDevice} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'

import jspreadsheet from '../../jsexcel/jexcel'
import XWindow from '../../components/thats/XWindow.vue'

import { Loading } from 'element-ui';
import InventoryTransferReceivingForm from './InventoryTransferReceivingForm.vue';

import HistoryDialog from "../../components/thats/HistoryDialog.vue";
export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
  inject : ['posShowroom','showroomsProvider','userDataProvider','userData','btsApproverProvider','btsReceiverProvider'],
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        ThatsTextarea,
        ThatsMoney,
        ThatsMultiSelect,
        ThatsDatePicker,
        XWindow,
        mdbContainer,
        InventoryTransferReceivingForm,
        UploadFromDevice,
        HistoryDialog
  },
    
  data(){

    const self = this;

    return {
        itemLabelOrigin : 1,
        labelModal : false,
        labelModalFromInvoice : false,
        labelModalFromDisplay : false,
        displayModalFromDisplay : false,
        selectedItemForLabel : null,
        itemInvoicesLoading : false,
        itemInvoices : [],
        selectedInvoices : [],
        pulloutItems : [],
        disabled : false,
        onPasteLoading : false,
        isMaximized : true,
        inputValue : 0,
        validated : false,
        CurrencySymbolLocal : 'QAR',
        active: 0,
        eventKeyCounter: 0,
        filteredShowroom : [],
        filteredShowroomx :[ { "id": 4, "name": "Kareem Kandil", "token": "rm0wMvbmOeYAlO1n", "mdbID": 4, "label": "Kareem Kandil", "value": 4 }],
        selection : [],
        data : [],
        form : {},
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        jexcelContainer : {
            height : 'calc(100vh - 165px)'
        },
        spreadsheetApplyLoading: false,
        spreadsheet: null,
        tabs : [
                { text: 'Request Details', slot: 'form-slot' },
                { text: 'Remarks', slot: 'remarks-slot' },
              ],

        gallery : [ "https://media.thatsliving.com/public/uploads/image-jpeg/small-4e5d8cb97525e59399d12ba1cba3f0c3-cnig-1702912-sl-001.jpg" ],
        index : null,

        inventoryTransferOptionsFrom : [],
        inventoryTransferOptionsTo : [],
        inventoryTransferItems : [],
        spreadsheetOptions : {
          copyCompatibility: false,
            download : false,
            csvFileName : 'costing',
            allowDeleteRow : true,
            allowInsertRow : true,
            allowInsertColumn : false,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : true,
            csvHeader:true,
            tableWidth : '1650px',
            tableHeight : 'calc(100vh - 165px)',
            tableOverflow : true,
            data:[],
             minDimensions:[6,50],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 2,
             columns: [],

            
            updateTable:function(instance, cell, col, row, val, label, cellName) {
                if((row % 2) == 1){
                    cell.parentNode.style.backgroundColor = '#FAFAFA';
                }
               
                if (col == 1) {
                  
                  if(val != null && val != ''){
                    cell.innerHTML = '<img src="' + val + '" style="width:80px;height:80px">';
                  }else{
                    cell.innerHTML = '<img src="https://media.thatsliving.com/public/uploads/image-png/full-e271b992b2be985538ba519189106b1f-tl100.png" style="width:80px;height:80px">';
                  }
                }


                if(col == 7){

                    let innerHtml = '';

                    if(['draft'].includes(self.form.status)){
                      innerHtml += `<div class="p-1 text-right d-block"><el-button size="mini" round><a href="javascript:void(0)" onClick="window.attachFile(${row})" class="d-block p-1"><i class="el-icon-paperclip"></i></a></el-button></di>`                      
                      innerHtml += `<div class="d-flex">`  
                    }
                      
                    const parsedvalue = JSON.parse(JSON.stringify(val))

                    if (Array.isArray(parsedvalue)) {
                      parsedvalue.forEach((element,index) => {
                          innerHtml += `<div style="display:inline-block">`
                            if(['draft'].includes(self.form.status)){  
                                innerHtml += `<a href="javascript:void(0)" onClick="window.attachFileRemove(${row},${index})" class="attach-file-remove text-danger p-1" style="position:absolute"><i class="el-icon-error"></i></a>`
                            }
                          innerHtml += `<a href="javascript:void(0)" onClick="window.attachFileView(${row},${index})" style="display:inline-block"><img src="${element.src}" class='img-thumbnail' style="width:90px"/></a></div>`
                         // innerHtml += `<a href="javascript:void(0)" onClick="window.attachFileView(${row},${index})"><img src="${element.src}" class='img-thumbnail' style="width:90px"/></a>`
                          
                      });
                    }

                   
                      
                    innerHtml += `</div>`

                    cell.innerHTML = innerHtml
                }

             

                if(col == 8){

                  const colSeries = instance.jexcel.getValueFromCoords(0, row);
                  const colOrigin = instance.jexcel.getValueFromCoords(5, row);
                  const colWarehouse = instance.jexcel.getValueFromCoords(6, row);
                /* const colId = instance.jexcel.getValueFromCoords(0, row);
                const rowValue = instance.jexcel.getRowData(row); */

                let innerHtml = '';
                cell.innerHTML = innerHtml
                  if(colSeries != '' &&  colSeries != null && colOrigin != '' &&  colOrigin != null && colWarehouse == 'WAREHOUSE'){

                    const existingButton = cell.querySelector('.add-label-div');
                      if(existingButton){
                        existingButton.remove();
                      }
  
                      const existingLabelContainer = cell.querySelector('.label-container');
                      if(existingLabelContainer){
                        existingLabelContainer.remove();
                      }
                    
                    
                      const addLabelLink = document.createElement("a");
                      addLabelLink.href = "javascript:void(0)";
                      addLabelLink.onclick = function(){
                        self.openAddLabelDialog(row);
                      };
                      addLabelLink.classList.add("d-block", "p-1");
                      addLabelLink.innerHTML = "<i class='el-icon-circle-plus-outline'></i> ADD LABEL";
                      const addLabelDiv = document.createElement("div");
                      addLabelDiv.classList.add("p-1", "text-right", "d-block","add-label-div");
                      addLabelDiv.appendChild(addLabelLink);
  
                      cell.appendChild(addLabelDiv);
                      
                      const parsedvalue = JSON.parse(JSON.stringify(val))

                      console.log("parsedvalue");
                      console.log(parsedvalue);
                      if (parsedvalue.hasOwnProperty('data') && Array.isArray(parsedvalue.data)) {
                          let container = document.createElement('div');
                          container.classList.add('label-container');

                          const createLabelContainer = document.createElement('div');
                          createLabelContainer.classList.add('d-flex');
                          createLabelContainer.innerHTML = parsedvalue.label;
                          container.appendChild(createLabelContainer);


                          parsedvalue.data.forEach((element) => {
                            const invItemDiv = document.createElement('div');
                            invItemDiv.classList.add('inv-item','alert','alert-info','m-1');
                            invItemDiv.innerHTML = `${element.label}`;
                            container.appendChild(invItemDiv);
                          })

                          let addClear = document.createElement('div');
                          addClear.classList.add('d-block', 'p-1', 'text-right');
                          const clearLabelLink = document.createElement("a");
                          clearLabelLink.href = "javascript:void(0)";
                          clearLabelLink.onclick = function(){
                            self.removeLabel(row);
                          };
                          clearLabelLink.classList.add("d-block", "p-1");
                          clearLabelLink.innerHTML = "<i class='el-icon-circle-close'></i> CLEAR";
                          addClear.appendChild(clearLabelLink);
                          container.appendChild(addClear);
  
                          cell.appendChild(container);
  
                        }
                  }


                      
                  
                  }

               

            },

             contextMenu: function(obj, x, y, e) {
                var items = [];

                  if(['draft'].includes(self.form.status)){

                      if (obj.options.allowInsertRow == true) {
                          items.push({
                              title:obj.options.text.insertANewRowBefore,
                              onclick:function() {
                                  obj.insertRow(1, parseInt(y), 1);
                              }
                          });
                          
                          items.push({
                              title:obj.options.text.insertANewRowAfter,
                              onclick:function() {
                                  obj.insertRow(1, parseInt(y));
                              }
                          });
                      }
    
                      if (obj.options.allowDeleteRow == true) {
                          items.push({
                              title:obj.options.text.deleteSelectedRows,
                              onclick:function() {
                                  obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                              }
                          });
                      }
                  }

                        /* if (obj.options.allowDeleteRow == true) {
                        items.push({
                            title:obj.options.text.deleteSelectedRows,
                            onclick:function() {
                                obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                            }
                        });

                    } */
                return items;
             },

             onblur: function(instance){
              self.setPasteLoadingFalse()
             },
             onchange: function(domElement, domElmentCell, column, row, value,oldValue){
                
                if(column == 0){
                    // self.spreadsheet.setValueFromCoords(1,row,'https://media.thatsliving.com/public/uploads/image-jpeg/thumb-46a757cc2eaf5b00ff32dd6f93468ddf-cla-016-123-hr.jpg',true)
                    

                    console.log(`${value} ====> ${oldValue} ==== row ${row}`)
                    if((value != null && value != '' && value != oldValue) || (oldValue == '' && oldValue == null)){
                      self.fnGetItem(value,row)
                    }
                }

      

             },
             onafterchanges : function(){
              self.setPasteLoadingFalse()
             },
             onpaste : function(){
               self.setPasteLoadingFalse()
              
             },
             onbeforechange : function(){
              self.setPasteLoadingTrue()
             }
        
       }
    }
  },
    

  created : function() {

        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        
       this.resetForm();

        if(this.isMaximized){
          this.width = window.innerWidth;
          this.height = window.innerHeight;
          this.spreadsheetOptions.tableWidth = `${window.innerWidth - 3}px`
          this.spreadsheetOptions.tableHeight = `calc(100vh - 195px)`
          this.jexcelContainer.height = `calc(100vh - 195px)`

        }else{
          this.width = 1300;
          this.height = 600;

          this.spreadsheetOptions.tableWidth = `${1300 - 3}px`
          this.spreadsheetOptions.tableHeight = `calc(100vh - 233px)`
          this.jexcelContainer.height = `calc(100vh - 233px)`
        }

        
  },

  mounted(){
    const self = this
    this.$eventBus.$on('attachFileRemove',function({row, index}){

      let cellData = self.spreadsheet.getValue(`H${(row+1)}`)

      const parsedvalue = JSON.parse(JSON.stringify(cellData))

  
      MessageBox.confirm('Are you sure you want to remove attachment?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                      const newData = [];
                      cellData.forEach((element,innerIndex) => {
                          if(innerIndex != index){
                            newData.push(element)
                          }
                      });

                      self.spreadsheet.setValue(`H${(row+1)}`,newData)

                     /*  const halfBeforeTheUnwantedElement = parsedvalue.slice(0, 2)
                      
                  const halfAfterTheUnwantedElement = parsedvalue(3);

                  const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement); */


                      /* const previousSecondElementOfTheArray = parsedvalue.splice(index,1)
                       */
                      /* 
                       */

                    })


       /*  if(self.formStatus == 'create'){

          
          self.gallery = rowData[7].map((item) => item.src,[])
           self.index = index
        }
        if(self.formStatus == 'update'){
          
          if(rowData.attachments.length > 0){
           self.gallery = rowData.attachments.map((item) => item.src,[])
           self.index = index
          }
        } */
    })
    this.$eventBus.$on('attachFileView',function({row, index}){
  
      const rowData = self.form.items[row]

        if(self.formStatus == 'create'){

          
          self.gallery = rowData[7].map((item) => item.src,[])
           self.index = index
        }
        if(self.formStatus == 'update'){

          let cellData = self.spreadsheet.getValue(`H${(row+1)}`)

          const parsedvalue = JSON.parse(JSON.stringify(cellData))

          if (Array.isArray(parsedvalue)) {
            if(parsedvalue.length > 0){
            self.gallery = parsedvalue.map((item) => item.src,[])
            self.index = index
            }
          }

          
          
        }
    })
    this.$eventBus.$on('attachFile',function({row}){
  
        //this.index = index
        
      //  alert("attach file")
      self.$refs.uploadRef.triggerBrowseFile(row);
    })
    this.$eventBus.$on('uploadDone',function({data,row}){


          let cellData = self.spreadsheet.getValue(`H${(row+1)}`)

          const parsedvalue = JSON.parse(JSON.stringify(cellData))

          if (!Array.isArray(parsedvalue)) {
            cellData = [];
          }

          cellData.push({src : data.path})
          self.spreadsheet.setValue(`H${(row+1)}`,cellData)
          // const cellData = self.spreadsheet.getValue(`H${row}`)
          // console.log("cellData")

          
          // const cellDatax = self.spreadsheet.getValue(`H${row}`)
          console.log("cellDatax")
          console.log(row)
          console.log(`H${(row+1)}`)
       /*  console.log("uploadDone")
        console.log(data)
        console.log(row) */
    })


  },


  methods: {
    ...mapActions('BringToShowroomModule',['getItem']),
    ...mapMutations('InventoryTransferRequestModule',['setSearchTerm','setFormData','setEmptyForm','setFormStatusCreate']),
    ...mapMutations('InventoryTransferReceiveModule',{
      setInventoryTransferReceiveFormData : 'setFormData'
    }),
    ...mapActions('InventoryTransferRequestModule',['storeRecord','updateRecord','getRecord','getItemInvoices']),
    ...mapActions('InventoryTransferModule',{
        storeInventoryTransfer : 'storeRecord',
        updateInventoryTransfer : 'updateRecord',
        cancelInventoryTransferRecord : 'cancelInventoryTransferRecord',
    }),
    
    ...mapMutations('HistoryModule',[
        'setModule',
        'setRecordId',
      ]),
      confirmInvoices(){

        this.$refs.fromInvoiceObserver.validate().then((passes) => {
          if (passes) {
        this.centerDialogVisible = false
        this.labelModalFromInvoice = false
        const row = this.selectedItemForLabel.row

        const confirmInvoicesArray = this.selectedInvoices.map((item)=>{
          return {...item, type : 'invoice', label : ` ${item.invoice}`, series : this.selectedItemForLabel.series, name : this.selectedItemForLabel.name, photo : this.selectedItemForLabel.photo,locationFrom : ''}
        },[])
        this.spreadsheet.setValueFromCoords(8,row,{type : 'invoice', label : 'FROM INVOICE', data : confirmInvoicesArray},true)
        this.$nextTick(() => {
            this.selectedItemForLabel = null;
            this.selectedInvoices = []
            this.itemInvoices = []
        })
        }
      })
      },

      confirmDisplay(){
        const self = this
       /*  const isValid = self.$refs.fromDisplayObserver.validate();
        console.log('isValid', isValid) */
        this.$refs.fromDisplayObserver.validate().then((passes) => {
          if (passes) {

            console.log('confirmed', passes)
            this.displayModalFromDisplay = false
            const row = this.selectedItemForLabel.row
    
            const confirmDisplayArray = this.pulloutItems.map((item)=>{
              return {series : item.series, photo : item.photo, name : item.name, dimension : item.dimension, quantity : item.quantity, type : 'display', label : `${item.locationFrom}`,locationFrom : item.locationFrom, row : item.row, touch_up : item.touch_up, pullout_date : item.pullout_date}
            },[])
            this.spreadsheet.setValueFromCoords(8,row,{type : 'display', label : 'FROM DISPLAY', data : confirmDisplayArray},true)
            return;
          }
        })
      },

      handleInvoiceSelectionChange(val){
        this.selectedInvoices = val
      },

      openAddLabelDialog(row){
        const tableData = this.spreadsheet.getJson();

        this.selectedItemForLabel = {...tableData[row], row : row}
        console.log("selectedItemForLabel",this.selectedItemForLabel)
/* 
        console.log("openAddLabelDialog")
        console.log(tableData[0])
        console.log(row) */
        this.labelModal = true
      },
      fnItemLabelOrigin(value){
        this.labelModal = false

        if(value == 'display'){
          this.displayModalFromDisplay = true
          this.pulloutItems = []
          this.$nextTick(() => {
            this.pulloutItems = [
              {
                ...this.selectedItemForLabel,
                touch_up : 'No',
                pullout_date : "",
                type : 'display'
              }
            ]
          })
        }else{
          this.labelModalFromInvoice = true
          this.itemInvoicesLoading = true
          this.itemInvoices = [];
          this.selectedInvoices = [];
          this.getItemInvoices({item : this.selectedItemForLabel.series, locationFrom : this.selectedItemForLabel.locationFrom}).then((response) => {
            this.itemInvoices = response.map((item)=>({...item, type : 'invoice',touch_up : 'No', expected_delivery_date : ""}),[])
            this.itemInvoicesLoading = false
          }).catch(() => {
            this.itemInvoicesLoading = false
          })
        }
        
      },

      checkIfRowSelected(transactionItemId){
          const hasSelected = this.selectedInvoices.filter((i) => i.id === transactionItemId).length
          return (hasSelected > 0) ? true : false
      },

      isInvoiceSelected(row,index){
        return this.selectedInvoices.filter((item)=>item.id == row.invoice && item.index == index).length > 0
      },

      removeLabel(row){
        this.spreadsheet.setValueFromCoords(8,row,{type : '', label : '', data : []}, true)
      },

      openHistoryDialog(){
            this.setModule('inventory-transfer-request')
            this.setRecordId(this.formData.id)
            this.$refs.historyDialog.openDialog();
        },

      formItemVar(){
        return {
                item_id: null,
                code: null,
                name : null,
                category : null,
                description: null,
                reference: null,
                qty: null,
                max: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                show_search: false,
                discount_type: null,
                custom_discount: {
                  apply : true,
                  discount : 0,
                  off_price : 0,
                  promo : 0,
                },
                total: 0,
                tbo: false,
                stock: null,
                status: null,
                future_stock: null,
                remarks: null,
                from_display: false
              }      
      },


    async setPasteLoadingTrue(){
      await this.$nextTick();
      this.onPasteLoading = true
      this.$forceUpdate();
      console.log("paste loading true")
    },
    async setPasteLoadingFalse(){
      await this.$nextTick();
      this.onPasteLoading = false
      this.$forceUpdate();
      console.log("paste loading false")
    },

    onResize({width,height}){
        
        if(!Number.isNaN(width) && !Number.isNaN(height)){
          console.log("update")
          console.log(width)
          console.log(height)


          if(width > 1300){
            this.spreadsheetOptions.tableWidth = `${width - 3}px`
            this.spreadsheetOptions.tableHeight = `calc(100vh - 195px)`
            this.jexcelContainer.height = `calc(100vh - 195px)`
            console.log(`calc(100vh - 165px)`)
          }else{
            this.spreadsheetOptions.tableWidth = `${1300 - 3}px`
            this.spreadsheetOptions.tableHeight = `calc(100vh - 233px)`
            this.jexcelContainer.height = `calc(100vh - 233px)`
            console.log(`calc(100vh - 203px)`)
          }
   
          this.spreadsheetOptions.style = this.spreadsheet.getStyle();
          const tableData = this.spreadsheet.getJson();
          
          this.spreadsheet.destroy();
          this.spreadsheetOptions.data = tableData

            this.setColumnProperty()

            if(['for-approval','approved','rejected'].includes(this.form.status)){
              this.spreadsheetOptions.minDimensions = [6,this.form.items]
            }else{
              this.spreadsheetOptions.minDimensions = [6,50]
            }

            this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, this.spreadsheetOptions);
            Object.assign(this, this.spreadsheet);
            this.spreadsheet.setWidth(window.innerWidth)


        }
        

       
      },
    resetForm(){
      this.form = JSON.parse(JSON.stringify(this.initialFormObject));
      this.form.showroom.id = this.userData.location.id
      this.form.prepared_by.id = this.userData.user.id
      this.form.prepared_by.name = this.userData.user.name
      this.form.requested_by.name = this.userData.user.name
      this.form.requested_by.id = this.userData.user.id
      this.formLoading = false
      },
    openDialog(){
        this.$refs.window.openDialog()
    },
    closeDialog(){
      this.$refs.window.closeDialog()
    },
    getActiveTabIndex(index){
      this.active = index
    },

    onSubmitForm(saveAs = 'save'){
       const self = this;
            const isValid = self.$refs.observer.validate();
            if(isValid){
                 const tableData = this.spreadsheet.getJson();
              if(tableData.length > 0){
                  const checkRow = tableData.filter((item) => item.series !== '',[]);
                  let noQuantity = 0;
                  let noDescription = 0;
                  let noLocationFromTo = 0;
                  checkRow.forEach(element => {
                      if(element.quantity == '' || element.quantity <= 0){
                        noQuantity++;
                      }

                      if(element.name == ''){
                        noDescription++;
                      }
                      if(element.locationFrom == '' || element.locationTo == ''){
                        noLocationFromTo++;
                      }
                  });
                  

                  if(noDescription > 0){
                          MessageBox({
                              title : 'Error',
                              message: 'Item description is required.',
                              type: 'error'
                          })
                      return false;
                  }

                  if(noQuantity > 0){
                          MessageBox({
                              title : 'Error',
                              message: 'Items quantity must be greater than zero.',
                              type: 'error'
                          })
                      return false;
                  }

                  if(noLocationFromTo > 0){
                          MessageBox({
                              title : 'Error',
                              message: 'Item Location From/To is required.',
                              type: 'error'
                          })
                      return false;
                  }

                  const warehouse = checkRow.filter((row)=>row.locationTo == 'WAREHOUSE');
                  const countLabels = checkRow.filter((row) =>
                  {
                    return row.locationTo == 'WAREHOUSE' && row.hasOwnProperty('labels') && row.labels.hasOwnProperty('data') && row.labels.data.length > 0
                  });

                  if(countLabels.length < warehouse.length){
                    MessageBox({
                              title : 'Error',
                              message: 'All items to be sent to WAREHOUSE must have labels.',
                              type: 'error'
                          })
                      return false;
                  }

                  const getValidData = checkRow.filter((row)=> {
                      return (row.quantity != '' && row.name != '' && row.locationFrom != '' && row.locationTo != '')
                  },[])

                  if(getValidData.length <= 0){
                    MessageBox({
                              title : 'Error',
                              message: 'No valid data',
                              type: 'error'
                          })
                      return false;
                  }


                  const formData = JSON.parse(JSON.stringify(this.form));

                  formData.items = getValidData.map((row)=>{
                  
                    const locationFrom = self.showroomLocation.filter((location) => location.prefix == row.locationFrom,[])[0];
                    row.location_from_id = (locationFrom) ? locationFrom.value : null;
                    const locationTo = self.showroomLocation.filter((location) => location.prefix == row.locationTo,[])[0];
                    row.location_to_id = (locationTo) ? locationTo.value : null;
                    row.attr = {
                      attachments : row.attachments,
                      labels : row.labels
                    }

                    return row
                  });

                  const showroom = self.showroomLocation.filter((location) => location.value == formData.showroom.id,[])[0];
                  formData.attr.showroom = {
                    prefix : showroom.prefix
                  }
                  formData.status = saveAs;


                  if(this.formStatus == 'create'){
                    this.storeRecord(formData).then((res) => {
                      self.$ThatsNotify.success({
                        message : "Record successfully created."
                      })
                      self.closeDialog()
                      self.$emit('success')
                    });
                  }

                  if(this.formStatus == 'update'){

                    if(saveAs == 'approved'){
                      MessageBox.confirm('Are you sure you want to Approve?', 'Warning', {
                          confirmButtonText: 'OK',
                          cancelButtonText: 'Cancel',
                          type: 'warning'
                          }).then(() => {
                            self.formLoading = true
                            this.updateRecord(formData).then((res) => {
                              self.$ThatsNotify.success({
                                message : "Record successfully created."
                              })
                              self.closeDialog()
                              self.$emit('success')
                              self.formLoading = false
                            });
                          })
                    }
                    else if(saveAs == 'rejected'){
                      MessageBox.confirm('Are you sure you want to reject BTS request?', 'Warning', {
                          confirmButtonText: 'OK',
                          cancelButtonText: 'Cancel',
                          type: 'warning'
                          }).then(() => {
                            self.formLoading = true
                            this.updateRecord(formData).then((res) => {
                              self.$ThatsNotify.success({
                                message : "Record successfully created."
                              })
                              self.closeDialog()
                              self.$emit('success')
                              self.formLoading = false
                            });
                          })
                    }else{
                      self.formLoading = true
                      this.updateRecord(formData).then((res) => {
                        self.formLoading = false
                        self.$ThatsNotify.success({
                          message : "Record successfully created."
                        })
                        self.closeDialog()
                        self.$emit('success')
                      });
                    }
                  }

                  console.log(getValidData)
                  
              }
            }

            
    },
    openHistory(){

    },

    cellClassName(){

    },

    createPopupTable(spreadsheetOptions){
        spreadsheetOptions.data = this.form.items


        console.log("spreadsheetOptions")
        console.log(spreadsheetOptions)
        console.log(this.form)


        this.setColumnProperty()

        if(['for-approval','approved','rejected'].includes(this.form.status)){
          spreadsheetOptions.minDimensions = [6,this.form.items]
        }else{
          spreadsheetOptions.minDimensions = [6,50]
        }

        this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, spreadsheetOptions);
        Object.assign(this, this.spreadsheet);
        this.spreadsheet.setWidth(window.innerWidth)

        
        
    },

    

        
        fnPrint(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)
          
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          const win = window.open(`${self.$route.path}/${url}`, "_blank", strWindowFeatures);
          win.document.title = 'Print';
          win.print()
        },


        renderTable(){
            const self = this;
            this.spreadsheetOptions.columns = this.tableColumns;
            this.createPopupTable(this.spreadsheetOptions);
        },

     
        currentDateSetter(){
          var MyDate = new Date();
          var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
          this.form.date = date;
        },

      dialogState(newState){
        if(!newState){
            this.spreadsheetOptions.style = {};
            this.spreadsheet.destroy();
            
            this.resetForm();
          return
        }
        
      },

      async fnGetItem(itemCode, index){
        const self = this;
        await this.getItem({item : itemCode, index : index}).then((res)=> {
            self.spreadsheet.setValueFromCoords(0,index,res.itemCode,true)
            self.spreadsheet.setValueFromCoords(1,index,res.photo,true)
            self.spreadsheet.setValueFromCoords(2,index,res.name,true)
            self.spreadsheet.setValueFromCoords(3,index,res.dimension,true)
            self.spreadsheet.setValueFromCoords(4,index,1,true)
            const parseIntIndex = parseInt(index)
            
           /*  self.spreadsheet.resetStyle(`A${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`B${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`C${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`D${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`E${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`F${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`G${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`H${parseIntIndex+1}`) */
            const backgroundColor = 'transparent'
            self.spreadsheet.setStyle(`A${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`B${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`C${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`D${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`E${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`F${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`G${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`H${parseIntIndex+1}`, 'background-color', backgroundColor)

        }).catch(() => {
          const parseIntIndex = parseInt(index)
          let backgroundColor = 'rgb(255 200 200)';
          if(( parseIntIndex % 2) == 1){
            backgroundColor = 'rgb(233 167 167)';            
          }
            self.spreadsheet.setStyle(`A${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`B${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`C${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`D${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`E${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`F${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`G${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`H${parseIntIndex+1}`, 'background-color', backgroundColor)

        })
    },

    setColumnProperty(){
      if(this.form.status != 'draft' && this.formStatus == 'update'){
        const currentUser = this.userData.user.id
          const checkCurrentUser =  this.form.approver.map((row) => row.id,[]);            
          
          if(checkCurrentUser.includes(currentUser) && this.form.status == 'for-approval'){
            this.spreadsheetOptions.columns = this.tableColumns.map((row) => {

              const enableColumn = ['quantity','description'];
              const readOnly = enableColumn.includes(row.name) ? false : true
              return {
                ...row,
                readOnly : readOnly
              }
            },[])
          }else{
            this.spreadsheetOptions.columns = this.tableColumns.map((row) => {
              return {
                ...row,
                readOnly : true
              }
            },[])
          }
      }

    },

    makeInventoryTransfer(){
        // alert(1)
        const self = this;


        self.$refs.observer.validate().then((isValid) => {
              if(isValid){
                const loading = Loading.service({
                        fullscreen: true ,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(255, 255, 255, 0.7)'
                      }); 
         
        
                this.storeInventoryTransfer({...this.form.inventory_transfer_form}).then((res) => {
                      self.getRecord({series : self.form.series}).then((res) =>{
                          loading.close();
        
                          self.$ThatsNotify.success({
                            message : "Record successfully updated."
                          })
                          self.closeDialog()
                          self.$emit('success')
        
                      })
                })
              }
          })

    },

    handleSelectionChange(v){

      this.form.inventory_transfer_form.items = v
    },

    canSelectRow(row, index){
      /* let inventoryTransferFrom = false
      let inventoryTransferTo = false

      if(this.form.hasOwnProperty('inventory_transfer_form') && this.form.inventory_transfer_form == null){
        console.log("nullllll")
      } */


     const inventoryTransferFrom = (this.form.hasOwnProperty('inventory_transfer_form') && this.form.inventory_transfer_form.showroomFrom != null && this.form.inventory_transfer_form.hasOwnProperty('showroomFrom') && this.form.inventory_transfer_form.showroomFrom.value !== null) ? true : false
      const inventoryTransferTo = (this.form.hasOwnProperty('inventory_transfer_form') && this.form.inventory_transfer_form.showroomTo != null && this.form.inventory_transfer_form.hasOwnProperty('showroomTo') && this.form.inventory_transfer_form.showroomTo.value !== null) ? true : false
        if(row.remaining <= 0 || !inventoryTransferTo || !inventoryTransferFrom){
           return false
        }
        return true;
    },

    disableInput(row){
        if(row.remaining <= 0){
           return true
        }
        return false;
    },
    
    quantityChange(v){
        // console.log(v)
        const code = v.code;
        const qty = parseInt(v.remaining);
          if(qty > v.max){
              v.remaining = v.max;
          }
          if(qty < 0){
            v.remaining = 0;
          }
      },


      fnPrintDoc(url){
        // this.printUrl = url
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },

      cancelInventoryTransfer(series){
          const self = this;
            // const deliveryNoteRow = [{...row}];
            MessageBox.confirm('Are you sure you want to cancel delivery note?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {
              self.cancelDeliveryNoteRecord(series).then((response) => {
                  self.setFormRecord(response.data)
              })
            })

        },

        cancelRecord(row){
            const self = this;
            MessageBox.confirm('Are you sure you want to cancel?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
              }).then(() => {
                self.cancelInventoryTransferRecord(row.series).then((res) => {
                    self.closeDialog()
                    self.$ThatsNotify.success({
                        message : "Record successfully updated."
                    })
                }).catch((error) => {

                    if(error.response.status == 422){
                        self.$ThatsNotify.warning({
                            message : error.response.data.message
                        })
                    }
                        console.log("error.response.data.message")
                        console.log(error.response.data.message)
                })
                
              })
          },



        receiveInventoryTransfer(row, action){
            const inventoryTransferData = JSON.parse(JSON.stringify(row))

            const inventoryTransferId = inventoryTransferData.id

            
            const filteredItems = this.form.inventory_transfer_items.filter((item)=>{
              return item.it_id == inventoryTransferId;
            },[])

            console.log("filteredItems");
            console.log(filteredItems);
            console.log(this.form.inventory_transfer_items);
            console.log(this.form.items);

            inventoryTransferData.items = filteredItems.map((item) => {
              const requestedItem =   this.form.items.filter((innerItem) => {
                    return innerItem.id == item.itr_item_id
                },[])[0]

                // const received_quantity = requestedItem.received_quantity == null ? requestedItem.quantity : item.received_quantity

                return {...item, photo : requestedItem.photo}
            },[])

            const mergeFormData = {...this.initialReceiveingFormObject,...inventoryTransferData, action : action}
            
            console.log(mergeFormData)
           this.setInventoryTransferReceiveFormData(JSON.parse(JSON.stringify(mergeFormData)))

           this.$nextTick(()=>{

            this.$refs.inventoryTransferReceivingForm.openDialog()
           })
        },

        receivedSuccess(){
          this.$emit('success')
          this.closeDialog()
        },

        selectTableLocation(option){

          return true
        },

        onInputLocationFrom(v){
          console.log("On input from")
          console.log(v)
        },
        onInputLocationTo(v){
          console.log("On input To")
          console.log(v)
        },

        onSelectLocation(v,origin){
          const self = this;

          if(origin == 'from'){
            this.form.inventory_transfer_form.showroomFrom.value =v
          }

          if(origin == 'to'){
            this.form.inventory_transfer_form.showroomTo.value =v
          }
          

          if(this.form.inventory_transfer_form.showroomFrom.value !== null &&  this.form.inventory_transfer_form.showroomTo.value === null){
              this.inventoryTransferItems = this.form.items.filter((item) => {
                    return item.location_from_id == self.form.inventory_transfer_form.showroomFrom.value;
                },[])
          }

          if(this.form.inventory_transfer_form.showroomFrom.value === null && this.form.inventory_transfer_form.showroomTo.value !== null){
              this.inventoryTransferItems = this.form.items.filter((item) => {
                    return item.location_to_id == self.form.inventory_transfer_form.showroomTo.value;
                },[])
          }


          if(this.form.inventory_transfer_form.showroomFrom.value !== null && this.form.inventory_transfer_form.showroomTo.value !== null){
              this.inventoryTransferItems = this.form.items.filter((item) => {
                    return item.location_from_id == self.form.inventory_transfer_form.showroomFrom.value && item.location_to_id == self.form.inventory_transfer_form.showroomTo.value;
                },[])
          }

          if(this.form.inventory_transfer_form.showroomFrom.value === null && this.form.inventory_transfer_form.showroomTo.value === null){
              this.inventoryTransferItems = JSON.parse(JSON.stringify(this.form.items))
              this.form.inventory_transfer_form.items = [];
          }

          if(this.form.inventory_transfer_form.showroomFrom.value === null || this.form.inventory_transfer_form.showroomTo.value === null){
              this.form.inventory_transfer_form.items = [];
          }
          /* console.log(v)
          console.log(origin)
          

           */

           this.eventKeyCounter++
      }
      
     
  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('InventoryTransferReceiveModule',{initialReceiveingFormObject : 'initialFormObject'}),
    ...mapGetters('InventoryTransferRequestModule',['getLoading','formData','formStatus','initialFormObject','putLoading','postLoading']),
      tblKey : function(){
        return 1;
      },

      showroomLocation : function(){

        const showroom = this.showroomsProvider();
        
        return showroom.map((item) => {
            return {...item, label : `${item.prefix} - ${item.label}`,prefix : item.prefix}
          },[])
      },

      rowShowroomLocation : function(){

      const showroom = this.showroomsProvider();

      return showroom.map((item) => {
          // return {...item, name :item.prefix,id : item.value}
          return item.prefix
        },[])
      },

      userDatax : function(){
        return this.userDataProvider()
      },
      documentApprover : function(){

        const btsApproverProvider = this.btsApproverProvider()
        return btsApproverProvider.map((item) => {
            return {...item, label : `${item.name}`,value : item.id}
          },[])
      },
      documentReceiver : function(){

        const intTransDocumentReceiver = this.btsReceiverProvider()

        let dReceiver = [];
        

        if(this.form.inventory_transfer_form.showroomTo.value !== null){

          return intTransDocumentReceiver.filter((user) => {
            return ['warehouse','delivery-team','showroom-manager','interior-designers','back-office','administrator'].some(item => user.role_slugs.includes(item)) && user.attr.location.includes(this.form.inventory_transfer_form.showroomTo.value)
          },[]).map((item) => {
              return {...item, label : `${item.name}`,value : item.id}
            },[])
        }
        return dReceiver;
      },

      tableColumns : function(){

        
        return [
                  {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'series',
                    title : 'Item Code'
                  },
                  {
                    type : 'text',
                    width : '90px',
                    align : 'left',
                    name : 'photo',
                    title : 'Photo',
                    readOnly : true
                  },

                  {
                    type : 'text',
                    width : '350px',
                    align : 'left',
                    name : 'name',
                    title : 'Description'
                  },

                  
                  {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'dimension',
                    title : 'Dimension'
                  },

                  {
                    type : 'numeric',
                    width : '100px',
                    align : 'center',
                    name : 'quantity',
                    title : 'Quantity',
                    mask:'#,##.00', decimal:'.' 
                  },

                
                  {
                    type: 'dropdown',
                    width: '100px',
                    title:'From',
                    name:'locationFrom',
                    autocomplete:true,
                    source: this.rowShowroomLocation,
                    multiple:false,
                    readOnly: false,
                    newOptions: true,
               
                    },

                    {
                    type: 'dropdown',
                    width: '100px',
                    title:'To',
                    name:'locationTo',
                    autocomplete:true,
                    source: this.rowShowroomLocation,
                    multiple:false,
                    readOnly: false,
                    newOptions: true
               
                    },
                    {
                      type : 'text',
                      width : '350px',
                      align : 'left',
                      name : 'attachments',
                      title : 'Attachment',
                      readOnly: false,
                    },

                    {
                      type : 'text',
                      width : '250px',
                      align : 'left',
                      name : 'labels',
                      title : 'LABELS',
                      readOnly: true,
                    },

                    {
                      type : 'text',
                      width : '350px',
                      align : 'left',
                      name : 'description',
                      title : 'Remarks'
                    },
                    {
                      type : 'hidden',
                      name : 'id',
                    },
                    {
                      type : 'hidden',
                      name : 'max',
                    },
                  
                    {
                      type : 'hidden',
                      name : 'remaining',
                    },
                    {
                      type : 'hidden',
                      name : 'location_from_id',
                    },
                    {
                      type : 'hidden',
                      name : 'location_to_id',
                    },{
                      type : 'hidden',
                      name : 'transfered_quantity',
                    },
                    {
                      type : 'hidden',
                      name : 'attr',
                    }
                  
                    



            ]
      },

      isAllowedToApproved : function(){
        return this.form.approver.map((row) => row.id,[]).includes(this.userData.user.id) || [2,18].includes(this.userData.user.id)
      },

      showMakeInventoryTransfer : function(){
          return this.form.status == 'approved'
      },

      showInventoryTransfer : function(){
          return this.form.status == 'approved'
      },

      hasLocationToWarehouse : function(){

     
        if(Object.keys(this.formData).length === 0) return false

        const filteredItems = this.formData.items.filter((item) => {
          return item.locationTo == 'WAREHOUSE' &&  item.hasOwnProperty('labels') && item.labels.hasOwnProperty('data') && item.labels.data.length > 0
        })

        if(!filteredItems) return false

        console.log("filteredItems",filteredItems)

        const filteredIds = filteredItems.map((item) => {
          return item.id
        },[])

        return filteredIds.join(",")

       
      }

      
    
  },

  watch: {
      'formData' : function(v){

        const self = this
        
        
        console.log(this.formStatus)
        
        if(this.formStatus == 'update'){
          console.log("test")
        console.log(v.status)

          if(['open','draft','for-approval'].includes(v.status)){

            console.log("self.documentApprover()")

            console.log(self.documentApprover)

            const getApprover = self.documentApprover.map((innerRow) => {
                      return innerRow.id
                  },[])

            v.approver = v.approver.filter((item) => {
              return getApprover.includes(item.id)
            },[]).map((row)=>{
                const getApprover = self.documentApprover.filter((innerRow) => {
                      return innerRow.id == row.id
                  },[])[0]
  
                return {...row,name : getApprover.name}
            },[])
          }

          if(['approved'].includes(v.status)){
            this.inventoryTransferItems = JSON.parse(JSON.stringify(v.items));
          }



          var MyDate = new Date();
          var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);


          this.form = JSON.parse(JSON.stringify({...v, inventory_transfer_form : {
                itr_id : v.id,
                date : date,
                showroom : v.showroom,
                showroomFrom : {
                    value : null,
                    name : null,
                },
                showroomTo : {
                    value : null,
                    name : null,
                },
                receiver_id : null,
                description: null,
                attr : v.attr,
                status : "open",
                prepared_by : {
                    id : this.userData.user.id,
                    name : this.userData.user.name 
                },
                items : []
            }}));

            if(v.items.length > 0){
              const itemLocationFrom = v.items.map((item) => item.locationFrom,[])
              const itemLocationTo = v.items.map((item) => item.locationTo,[])

              const uniqueArrayFrom = [];

              for (let i = 0; i < itemLocationFrom.length; i++) {
                if (uniqueArrayFrom.indexOf(itemLocationFrom[i]) === -1) {
                  uniqueArrayFrom.push(itemLocationFrom[i]);
                }
              }

              const uniqueArrayTo = [];

              for (let i = 0; i < itemLocationTo.length; i++) {
                if (uniqueArrayTo.indexOf(itemLocationTo[i]) === -1) {
                  uniqueArrayTo.push(itemLocationTo[i]);
                }
              }



              this.inventoryTransferOptionsTo = self.showroomLocation.filter((location) => uniqueArrayTo.includes(location.prefix),[]);
              this.inventoryTransferOptionsFrom = self.showroomLocation.filter((location) => uniqueArrayFrom.includes(location.prefix),[]);
                          
            }
          
          this.$nextTick(()=>{
            
            if(this.showMakeInventoryTransfer){
              const checkIfHasInventoryTransfer = this.tabs.filter((item) => item.slot == 'inventory-transfer-slot',[]);
              
              if(checkIfHasInventoryTransfer.length <= 0){
                this.tabs.push({ text: 'Inventory Transfer', slot: 'inventory-transfer-slot' })
              }
              this.eventKeyCounter++;
            }
            if(this.showInventoryTransfer && this.form.inventory_transfer.length > 0){
              const checkIfHasInventoryTransfer = this.tabs.filter((item) => item.slot == 'inventory-transfer-slot',[]);
              
              if(checkIfHasInventoryTransfer.length <= 0){
                this.tabs.push({ text: 'Inventory Transfer', slot: 'inventory-transfer-slot' })
              }
              this.eventKeyCounter++;
            }

            if(self.spreadsheet != null){
              self.spreadsheet.destroy();
            }
            self.spreadsheetOptions.columns = this.tableColumns;

            this.setColumnProperty()
            console.log("self.spreadsheetOptions.columns")
            console.log(self.spreadsheetOptions.columns)

            self.createPopupTable(this.spreadsheetOptions);
            self.spreadsheet.setData(this.form.items)
          })

          this.active = 0;
                    

        }else{

          if(self.spreadsheet != null){
            self.spreadsheet.destroy();
          }

          this.tabs = [
                { text: 'Request Details', slot: 'form-slot' },
                { text: 'Remarks', slot: 'remarks-slot' }
              ]
          this.resetForm();
          this.currentDateSetter();
          this.renderTable();
          this.active = 0;
        }
           
      },

      

      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheet.setData(this.form.items)
        })


        }
      },

      documentApprover : function(value){

        
          this.form.approver = JSON.parse(JSON.stringify(value))
      },

      showroomLocation : function(value){

          const allowedLocation = this.userData.location_prefix
        this.filteredShowroom = value.filter((i) =>{
            return allowedLocation.includes(i.prefix)
        }).map((i) => {
          return i.prefix
        })
      }

      



  }

}
</script>

<style lang="scss">



.bts-form-tabs{
    .nav-tabs{
      margin-left: 10px;border-bottom: none!important;
      .nav-item{
        a.active{
          border-bottom: 1px solid #0056b3;
        }
      }
    }

    .tab-content{
      height: auto!important;border-top:1px solid #dee2e6;
    }
}

</style>