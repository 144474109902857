import { CategoryService,ApiService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const CategoryModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        data: {
            columns: [

                {
                    label: 'ID',
                    field: 'id',
                    sort: 'asc',
                    clickable: true,
                    width: '100px'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    clickable: true,
                    width: '200px'
                },
                {
                    label: 'Attributes',
                    field: 'attributes',
                    sort: 'asc',
                },
                {
                    label: 'Area',
                    field: 'area_text',
                    sort: 'asc',
                    width: '150px'
                },
                {
                    label: 'Brands ID',
                    field: 'brand_text',
                    sort: 'asc',
                    

                },

                /* {
                    label: 'Category ID',
                    field: 'series',
                    sort: 'asc',
                    clickable: true,
                    width: '100px'

                }, */

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {name : null,area : null},

        formStatus: 'create',
        formRecord: {},
        all : [],
        allLoading : false,

    },
    actions: {
        async GetBrandByArea({ dispatch }, params) {
            return await new Promise((resolve) => {
                CategoryService.get('/filter-brand-area', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {
                            const row = { label : element.name, value: element.mdbID,area : element.area_id }
                            res.push(row)
                        })
                    }
                    resolve(res)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        async GetBrandByAreaErp({ dispatch }, params) {
            return await new Promise((resolve) => {
                CategoryService.get('/filter-brand-area-erp', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {
                            const row = { label : element.name, value: element.mdbID,area : element.area_id }
                            res.push(row)
                        })
                    }
                    resolve(res)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        
        GetCategory({ dispatch }, params) {
            return new Promise((resolve) => {
                CategoryService.get('/filter', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {
                            const row = { label : element.name, value: element.mdbID }
                            res.push(row)
                        })
                    }
                    resolve(res)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async GetNextCode({ dispatch }) {
            return await new Promise((resolve) => {
                CategoryService.get('/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async asyncGetData({ rootState, commit, dispatch }) {
            const { loading, length, page, filters, searchTerm } = rootState.CategoryModule
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('LoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    CategoryService.get('\/data', params).then((response) => {
                        commit('SetPage', response.data.current_page)
                        commit('SetTotal', response.data.total)
                        commit('SetData', response.data.data)
                        commit('LoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        //reject(error)
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },



        async StoreRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.CategoryModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    CategoryService.store(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        UpdateRecord({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.CategoryModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    CategoryService.put(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        UpdateOrder({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.CategoryModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    CategoryService.updateOrder(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async DeleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.CategoryModule.Deleteloading;
                if (!loading) {
                    commit('DeleteloadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    CategoryService.deleteSelected(tokens).then((response) => {
                        commit('DeleteloadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('DeleteloadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async getAllRecords({commit}, params) {
            commit('setAllLoading',true)
            return await new Promise((resolve,reject) => {
                ApiService.get('category-master/all', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    commit('setAllLoading',false)
                    reject(error)
                })
            })
        },

        async createOrUpdate({ commit }, payload) {
            commit('LoadingTrue')
            return await new Promise((resolve, reject) => {
                ApiService.store('category-master/create-or-update', payload).then((response) => {
                    commit('LoadingFalse')
                    const responseBody = response.data;
                    resolve(responseBody)
                }).catch((error) => {
                    commit('LoadingFalse')
                    reject(error)
                })
            })
        },

        async mergeCategories(_, payload) {
            return await new Promise((resolve, reject) => {
                ApiService.update('category-master/merge', payload).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        async filterBrandByArea({ dispatch }, params) {
            return await new Promise((resolve) => {
                ApiService.get('category-master/filter-brand-by-area', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue: (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload) {
            state.data.rows = payload
        },
        SetPage: (state, payload) => {
            state.page = payload
        },
        SetTotal: (state, payload) => {
            state.total = payload
        },
        SetLength: (state, payload) => {
            state.length = payload
        },
        SetCode: (state, payload) => {
            state.form.item_code = payload
        },

        SetFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormRecord: (state, payload) => {
            state.formRecord = payload
        },
        setAll : (state, payload) => {
            state.all = payload || []
        },
        setAllLoading : (state, payload) => {
            state.allLoading = payload
        }

    },
    getters: {
        Loading: state => state.loading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord,
        all: state => state.all,
        allLoading: state => state.allLoading
    }
}


