import Vue from 'vue'
import Router from 'vue-router'
// import routes from './routes'
// import { sync } from 'vuex-router-sync'
Vue.use(Router)

// const router = createRouter()

// sync(store, router)
import Login from '../views/auth/Login'
import Forgot from '../views/auth/Forgot'
import Reset from '../views/auth/Reset'
import Welcome from '../views/Welcome'
import Items from '../views/item/Item'
import CustomerVendor from '../views/customer-vendor/CustomerVendor'
import Collection from '../views/collection/Collection'
import Category from '../views/category/Category'
import Codechange from '../views/codechange/Codechange.vue'
import Brand from '../views/brand/Brand'
import Warehouse from '../views/warehouse/Warehouse'
import Showrooms from '../views/showrooms/Showrooms'
import ForeignCurrency from '../views/currency/ForeignCurrency'
import Contacts from '../views/contacts/Contacts'
import Vendors from '../views/vendors/Vendors'
import Employees from '../views/employees/Employees'
import Others from '../views/others/Others'
import User from '../views/user/User'
import CostingTool from '../views/costing-tool/CostingTool'
import SapPurchaseOrder from '../views/sap-purchase-order/PurchaseOrder'
import PurchaseOrder from '../views/purchase-order-v2/PurchaseOrder'
import GoodsReceiptNote from '../views/goods-receipt-note/GoodsReceiptNote'
import PurchaseOrderPrint from '../views/purchase-order/PurchaseOrderPrint'
import CustomerOrder from '../views/customer-order/CustomerOrder'
import SalesPromo from '../views/sales-promo/SalesPromo'
// import Sales from '../views/sales/Sales'
// import Transaction from '../views/sales/Transaction'
import Quotation from '../views/transaction/Quotation'
import SalesQuotation from '../views/rebuild-transaction/Quotation'
import TransactionSap from '../views/delivery/TransactionSap'
import TransactionV1 from '../views/transaction/Transaction'
import CustomerSegment from '../views/customer-segment/CustomerSegment'
import SalesOrder from '../views/sales-order/SalesOrder'
import Fabric from '../views/fabric/Fabric'
import Wallpapers from '../views/wallpapers/Wallpapers'
import Colour from '../views/colour/Colour'
import Material from '../views/material/Material'
import Designation from '../views/designation/Designation'
import OnlineApplication from '../views/online-application/OnlineApplication'
import UserLogo from '../views/user-log/UserLog'
import Role from '../views/role/Role'
import Delivery from '../views/delivery/Delivery'
import Receiving from '../views/receiving/Receiving'
import ReturnExchange from '../views/return-exchange/ReturnExchange'
import Barcode from '../views/barcode/Barcode'
import GenerateSerialShowroom from '../views/barcode/GenerateSerialShowroom'
import BarcodeFabric from '../views/barcode/BarcodeFabric'
/* import PurchaseOrderV1 from '../views/purchase-order-v1/PurchaseOrderV1' */
import DemoTable from '../views/DemoTable'
// import ItemCancellation from '../views/item-cancellation/ItemCancellation'
import Reports from '../views/reports/Reports'
import SalesReportShowroomManager from '../views/reports/SalesReportShowroomManager'
import ReportByBrand from '../views/reports/ReportByBrand'
import ReportByMonth from '../views/reports/ReportByMonth'
/* import ItemExchange from '../views/item-exchange/ItemExchange'
import ItemRefund from '../views/item-refund/ItemRefund' */
import WhPhysicalCount from '../views/wh-physical-count/WhPhysicalCount'
import ShipmentReceived from '../views/shipment-received/ShipmentReceived'
import IncomingShipment from '../views/incoming-shipment/IncomingShipment'
import BillsOfMaterials from '../views/bill-of-materials/BillsOfMaterials'
import LabelPrinting from '../views/label-printing/LabelPrinting'
import GenerateSerial from '../views/generate-serial/GenerateSerial'
import GenerateSerialVersionTwo from '../views/generate-serial/GenerateSerialVersionTwo'
import ReprintSticker from '../views/generate-serial/ReprintSticker'
import PrintQrCode from '../views/generate-serial/PrintQrCode'
import ImportNewItem from '../views/generate-serial/ImportNewItem'
import ImportNewItemPrice from '../views/generate-serial/ImportNewItemPrice'
import ImportBulkItemUpdate from '../views/generate-serial/ImportBulkItemUpdate'
import CodeGenerator from '../views/code-generator/CodeGenerator'

import Inventory from '../views/inventory/Inventory'
import InventoryTransferRequest from '../views/inventory-transfer-request/InventoryTransferRequest'
import InventoryTransfer from '../views/inventory-transfer-request/InventoryTransfer'
import DeliveryScheduleCalendar from '../views/delivery-schedule-calendar/DeliveryScheduleCalendar'
import ItemPromotion from '../views/item-promotion/Index'

import DemoData from '../views/generate-serial/DemoData'
import Authorization from '../views/authorization/UserAuthorization'
import SalesTarget from "../views/sales-target/SalesTarget.vue";
import ShowroomTarget from "../views/showroom-target/ShowroomTarget.vue";

import InventoryItemMaster from "../views/inventory-item-master/InventoryItemMaster.vue";
import ItemMaster from "../views/item-master/ItemMaster.vue";
import BusinessPartner from "../views/business-partner/BusinessPartner.vue";
import PurchaseDeliveryNote from "../views/local-purchase-delivery-note/PurchaseDeliveryNote.vue";
import LoyaltyMember from "../views/loyalty-members/LoyaltyMember.vue";
import ChatWebhook from "../views/chat-webhook/Index.vue";

/* import UpdateInvoice from '../views/generate-serial/UpdateInvoice' */
import store from '../store'
// console.log(store)
import print from '../middleware/print'
import pabulicPage from '../middleware/public'
import auth from '../middleware/auth'
import guest from '../middleware/guest'
import LoyaltyCard from "../views/loyalty-card/LoyaltyCard.vue";
import Tier from "../views/tier/Tier.vue";
import PhysicalCount from "../views/physical-count/PhysicalCount.vue";
import PostedInventory from "../views/posted-inventory/PostedInventory.vue";
import TierBeneficiary from "../views/tier-beneficiary/TierBeneficiary.vue";
import LoyaltyTransaction from "../views/loyalty-transaction-reports/LoyaltyTransaction.vue";
import LoyaltyReport from "../views/loyalty-reports/LoyaltyReport.vue";
import Coupon from "../views/coupon/Coupon.vue";
import IssueCoupon from "../views/issue-coupon/IssueCoupon.vue";
import SMSTemplate from "../views/sms-template/SMSTemplate.vue";
import Voucher from "../views/voucher/Voucher.vue";
import LocationSetup from "../views/location-setup/Index.vue";
import AppCms from "../views/app-cms/AppCms.vue";
import CustomerLog from "../views/customer-logs/CustomerLog.vue";
import CallLog from "../views/call-log/CallLog.vue";
import GenerateDigitalSignature from '../views/GenerateDigitalSignature'
import InventoryTransferV2 from "../views/inventory-transfer-v2/InventoryTransferV2.vue";
import ReceiveTransfer from "../views/receive-transfer/ReceiveTransfer.vue";
import SubCategory from "../views/sub-category/SubCategory.vue";
let user = JSON.parse(localStorage.getItem('user'));

let token = null;

if(user !== null){
    token = user.token
}
const routes = [
    { path: '/digital-signature', name: 'digital-signature', component: GenerateDigitalSignature, meta: { middleware : pabulicPage } },
    { path: '/demo-data/:id?', name: 'demo-data', component: DemoData, meta: { middleware: auth } },
    { path: '/forgot-password', name: 'forgot', component: Forgot, meta: { middleware: guest } },
    { path: '/reset/:token', name: 'reset', component: Reset, meta: { middleware: guest } },
    { path: '/login', name: 'login', component: Login, meta: { middleware: guest } },
    { path: '/', name: 'welcome', component: Welcome, meta: { middleware: [auth]}},
    { path: '/logout', name: 'logout', component: Welcome, meta: { middleware: [auth]}},
/*     { path: '/user', name: 'user', component: Login, meta: { middleware: [auth] } }, */
    { path: '/items', name: 'items', component: Items, meta: { middleware: [auth] } },
    { path: '/customer-vendor', name: 'customervendor', component: CustomerVendor, meta: { middleware: [auth] } },
    { path: '/collection', name: 'collection', component: Collection, meta: { middleware: [auth] } },
    { path: '/category', name: 'category', component: Category, meta: { middleware: [auth] } },
    { path: '/brand', name: 'brand', component: Brand, meta: { middleware: [auth] } },
    /* { path: '/showrooms', name: 'showrooms', component: Showrooms, meta: { middleware: [auth] } }, */
    { path: '/warehouse', name: 'warehouse', component: Warehouse, meta: { middleware: [auth] } },
    { path: '/foreigncurrency', name: 'foreigncurrency', component: ForeignCurrency, meta: { middleware: [auth] } },
    { path: '/contacts', name: 'contacts', component: Contacts, meta: { middleware: [auth] } },
    { path: '/vendors', name: 'vendors', component: Vendors, meta: { middleware: [auth] } },
    { path: '/employees', name: 'employees', component: Employees, meta: { middleware: [auth] } },
    { path: '/others', name: 'others', component: Others, meta: { middleware: [auth] } },
    { path: '/user', name: 'user', component: User, meta: { middleware: [auth] } },
    { path: '/role', name: 'role', component: Role, meta: { middleware: [auth] }},
    { path: '/purchase-order', name: 'purchase-order', component: PurchaseOrder, meta: { middleware: [auth] } },
    { path: '/goods-receipt-note', name: 'goods-receipt-note', component: GoodsReceiptNote, meta: { middleware: [auth] } },
/*     { path: '/purchase-order-v1', name: 'purchase-order-v1', component: PurchaseOrderV1, meta: { middleware: [auth] } }, */
    { path: '/customer-order', name: 'customer-order', component: CustomerOrder, meta: { middleware: [auth] } },
    { path: '/item-promotion', name: 'sales-promo', component: ItemPromotion, meta: { middleware: [auth] } },
    // { path: '/sales', name: 'sales', component: Sales, meta: { middleware: [auth] } },
    { path: '/sales-quotations', name: 'sales-quotations', component: SalesQuotation, meta: { middleware: [auth] } },
    { path: '/quotations', name: 'quotations', component: Quotation, meta: { middleware: [auth] } },
    { path: '/transaction-v1', name: 'sales', component: TransactionV1, meta: { middleware: [auth] } },
    { path: '/customer-segments', name: 'customer-segments', component: CustomerSegment, meta: { middleware: [auth] } },
    { path: '/loyalty-cards', name: 'loyalty-cards', component: LoyaltyCard, meta: { middleware: [auth] } },
    { path: '/tiers', name: 'tiers', component: Tier, meta: { middleware: [auth] } },
    // { path: '/transaction', name: 'sales', component: Transaction, meta: { middleware: [auth] } },
    { path: '/sales-order', name: 'quotation', component: SalesOrder, meta: { middleware: [auth] } },
    { path: '/fabric-textile', name: 'fabric', component: Fabric, meta: { middleware: [auth] } },
    { path: '/wallpapers', name: 'wallpapers', component: Wallpapers, meta: { middleware: [auth] } },
    { path: '/colour', name: 'colour', component: Colour, meta: { middleware: [auth] } },
    { path: '/material', name: 'material', component: Material, meta: { middleware: [auth] } },
    { path: '/designation', name: 'designation', component: Designation, meta: { middleware: [auth] } },
    { path: '/delivery-archive', name: 'delivery', component: Delivery, meta: { middleware: [auth] } },
    { path: '/user-log', name: 'user-log', component: UserLogo, meta: { middleware: [auth] } },
    { path: '/sales-return', name: 'sales-return', component: ReturnExchange, meta: { middleware: [auth] } },
    { path: '/receiving', name: 'receiving', component: Receiving, meta: { middleware: [auth] } },
    { path: '/barcode', name: 'barcode', component: Barcode, meta: { middleware: [auth] } },
    { path: '/barcode-fabric', name: 'barcode-fabric', component: BarcodeFabric, meta: { middleware: [auth] } },
    { path: '/demo-table', name: 'demo-table', component: DemoTable, meta: { middleware: [auth] } },
   /*  { path: '/item-cancellation', name: 'item-cancellation', component: ItemCancellation, meta: { middleware: [auth] } }, */
    { path: '/online-application', name: 'online-application', component: OnlineApplication, meta: { middleware: [auth] } },
    { path: '/sales-reports', name: 'sales-reports', component: Reports, meta: { middleware: [auth] } },
    { path: '/sales-report-by-brand', name: 'sales-report-by-brand', component: ReportByBrand, meta: { middleware: [auth] } },
    { path: '/sales-report-showroom-manager', name: 'sales-report-showroom-manager', component: SalesReportShowroomManager, meta: { middleware: [auth] } },
    { path: '/sales-report-by-month', name: 'sales-report-by-month', component: ReportByMonth, meta: { middleware: [auth] } },
    { path: '/sap-invoices', name: 'sap-invoices', component: TransactionSap, meta: { middleware: [auth] } },
   /*  { path: '/item-exchange', name: 'item-exchange', component: ItemExchange, meta: { middleware: [auth] } },
    { path: '/item-refund', name: 'item-refund', component: ItemRefund, meta: { middleware: [auth] } }, */
    { path: '/physical-count', name: 'physical-count', component: WhPhysicalCount, meta: { middleware: [auth] } },
    { path: '/shipment-received', name: 'shipment-received', component: ShipmentReceived, meta: { middleware: [auth] } },
    { path: '/incoming-shipment', name: 'incoming-shipment', component: IncomingShipment, meta: { middleware: [auth] } },
    { path: '/label-printing', name: 'label-printing', component: LabelPrinting, meta: { middleware: [auth] } },
    { path: '/bill-of-material', name: 'bill-of-material', component: BillsOfMaterials, meta: { middleware: [auth] } },
    { path: '/code-change', name: 'code-change', component: Codechange, meta: { middleware: [auth] } },
    { path: '/generate-serial-number', name: 'generate-serial-number', component: GenerateSerial, meta: { middleware: [auth] } },
    { path: '/generate-serial-number-v2', name: 'generate-serial-number-v2', component: GenerateSerialVersionTwo, meta: { middleware: [auth] } },
    { path: '/generate-serial-number-showroom', name: 'generate-serial-number-showroom', component: GenerateSerialShowroom, meta: { middleware: [auth] } },
    { path: '/reprint-sticker', name: 'reprint-sticker', component: ReprintSticker, meta: { middleware: [auth] } },
    { path: '/print-qr-code', name: 'print-qr-code', component: PrintQrCode, meta: { middleware: [auth] } },
    { path: '/import-new-item', name: 'import-new-item', component: ImportNewItem, meta: { middleware: [auth] } },
    { path: '/update-price', name: 'update-price', component: ImportNewItemPrice, meta: { middleware: [auth] } },
    { path: '/bulk-item-update', name: 'bulk-item-update', component: ImportBulkItemUpdate, meta: { middleware: [auth] } },
    { path: '/costing-tool', name: 'costing-tool', component: CostingTool, meta: { middleware: [auth] } },
    { path: '/code-generator', name: 'code-generator', component: CodeGenerator, meta: { middleware: [auth] } },
    { path: '/bring-to-showroom', name: 'bring-to-showroom', component: InventoryTransferRequest, meta: { middleware: [auth],reload: true    }, query : {src : 'showroom'} },
   /*  { path: '/inventory', name: 'inventory', component: Inventory, meta: { middleware: [auth],reload: true }}, */
    { path: '/inventory-transfer-request', name: 'inventory-transfer-request', component: InventoryTransferRequest, meta: { middleware: [auth],reload: true }, query : {src : 'warehouse'} },
    { path: '/inventory-transfer', name: 'inventory-transfer', component: InventoryTransfer, meta: { middleware: [auth],reload: true }, query : {src : 'warehouse'} },
    { path: '/purchase-order-for-approval', name: 'purchase-order-for-approval', component: SapPurchaseOrder, meta: { middleware: [auth] }},
    { path: '/delivery-schedule-calendar', name: 'delivery-schedule-calendar', component: DeliveryScheduleCalendar, meta: { middleware: [auth] } },
    { path: '/purchase-order/print/:hash', name: 'purchase-order-print', component: PurchaseOrderPrint, meta: { middleware: [print] } },
    { path: '/user-authorization', name: 'user-authorization', component: Authorization, meta: { middleware: [auth] } },
    { path: '/sales-target', name: 'sales-target', component: SalesTarget, meta: { middleware: [auth] } },
    { path: '/showroom-target', name: 'showroom-target', component: ShowroomTarget, meta: { middleware: [auth] } },

    { path: '/inventory-item-master', name: 'inventory-item-master', component: InventoryItemMaster, meta: { middleware: [auth] } },

    { path: '/physical-inventory-count', name: 'physical-inventory-count', component: PhysicalCount, meta: { middleware: [auth] } },
    { path: '/posted-inventory', name: 'posted-inventory', component: PostedInventory, meta: { middleware: [auth] } },
    { path: '/inventory-transfer-v2', name: 'inventory-transfer-v2', component: InventoryTransferV2, meta: { middleware: [auth] } },
    { path: '/receive-transfer', name: 'receive-transfer', component: ReceiveTransfer, meta: { middleware: [auth] } },

    { path: '/item-master-raw-materials', name: 'item-master-raw-materials', component: ItemMaster, meta: { middleware: [auth] } },
    { path: '/loyalty-members', name: 'loyalty-members', component: LoyaltyMember, meta: { middleware: [auth] } },
    { path: '/local-supplier', name: 'local-supplier', component: BusinessPartner, meta: { middleware: [auth] } },
    { path: '/local-purchase-delivery-note', name: 'local-purchase-delivery-note', component: PurchaseDeliveryNote, meta: { middleware: [auth] } },
    { path: '/tier-benefits', name: 'tier-beneficiary', component: TierBeneficiary, meta: { middleware: [auth] } },
    { path: '/transaction-reports', name: 'transaction-reports', component: LoyaltyTransaction, meta: { middleware: [auth] } },
    { path: '/loyalty-program-reports', name: 'loyalty-program-reports', component: LoyaltyReport, meta: { middleware: [auth] } },
    { path: '/coupons', name: 'coupons', component: Coupon, meta: { middleware: [auth] } },
    { path: '/issue-coupons', name: 'issue-coupons', component: IssueCoupon, meta: { middleware: [auth] } },
    { path: '/customer-logs', name: 'customer-logs', component: CustomerLog, meta: { middleware: [auth] } },
    { path: '/call-logs', name: 'call-logs', component: CallLog, meta: { middleware: [auth] } },
    { path: '/app-cms', name: 'app-cms', component: AppCms, meta: { middleware: [auth] } },
    { path: '/sms-template', name: 'sms-template', component: SMSTemplate, meta: { middleware: [auth] } },
    { path: '/vouchers', name: 'vouchers', component: Voucher, meta: { middleware: [auth] } },
    { path: '/showrooms', name: 'showrooms', component: LocationSetup, meta: { middleware: [auth] } },
    { path: '/chat-webhook', name: 'chat-webhook', component: ChatWebhook, meta: { middleware: [auth] } },
    { path: '/sub-category', name: 'sub-category', component: SubCategory, meta: { middleware: [auth] } },

]

const router = new Router({
    mode: 'history',
    routes
})

function nextFactory(context,middleware,index) {
    const subsequentMiddleware = middleware[index]
    if(!subsequentMiddleware) return context.next
    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context,middleware,index + 1);
        subsequentMiddleware({...context,next : nextMiddleware})
    }
}

function resolveComponents(components) {
    return Promise.all(components.map(component => {
        return typeof component === 'function' ? component() : component
    }))
}

function getMiddleware(components) {
    const middleware = [...globalMiddleware]

    components.filter(c => c.middleware).forEach(component => {
        if (Array.isArray(component.middleware)) {
            middleware.push(...component.middleware)
        } else {
            middleware.push(component.middleware)
        }
    })

    return middleware
}

async function beforeEach(to, from, next){

    // console.log("beforee");
   /*  let components = []

    try {
        // Get the matched components and resolve them.
        components = await resolveComponents(
            router.getMatchedComponents({ ...to })
        )
    } catch (error) {
        if (/^Loading( CSS)? chunk (\d)+ failed\./.test(error.message)) {
            window.location.reload(true)
            return
        }
    }

    if (components.length === 0) {
        return next()
    }
 */

    // console.log(store)
    store.commit('TableModule/SetFilters',{});
    store.commit('TableModule/SetTerm',null);
    store.commit('TableModule/SetSelection',[]);

    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
        const context = {
            from,
            next,
            router,
            to
        }
        const nextMiddleware = nextFactory(context, middleware, 1)
        return middleware[0]({ ...context, next: nextMiddleware })
    }

    return next()

}
router.beforeEach(beforeEach)

export default router
