<template>
    <div>
        <ThatsTable title="Items" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="true" @open="openNewModal" @viewSoldTo="viewSoldTo" @row-click="rowClick" :key="tableKey" :filterFormData="filterForm" @clearFilterForm="clearFilterForm"></ThatsTable>
        <SubCategoryForm ref="subCategoryForm" @success="refreshTable"></SubCategoryForm>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect,mdbModal,mdbModalHeader,
    mdbModalFooter,  mdbModalBody, mdbInput,mdbBadge,mdbDropdown,mdbDropdownToggle,mdbDropdownMenu,mdbDropdownItem} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'

import { ThatsDatePicker, ThatsMultiSelect, ThatsInput, ThatsAutocomplete,ThatsNumber} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';
import SubCategoryForm from './SubCategoryForm.vue'
// import IntegrationWindow from '../../components/thats/IntegrationWindow.vue'
import ItemSoldToWindow from "./ItemSoldToWindow.vue"
import RelatedDocumentPurchaseOrderDialog from "./RelatedDocumentPurchaseOrderDialog.vue"
import RelatedDocumentStockHistoryDialog from "./RelatedDocumentStockHistoryDialog.vue"
import RelatedDocumentIncomingContainerDialog from "./RelatedDocumentIncomingContainerDialog.vue"
import InvoiceForm from '../transaction/InvoiceForm.vue'
import BulkUpdateCountryOfOrigin from './BulkUpdateCountryOfOrigin'
import BulkPrintQrCode from './BulkPrintQrCode'
import BulkUpdateItem from './BulkUpdateItem.vue';
import VueSlider from 'vue-slider-component'
import UpdateQuantity from './UpdateQuantity.vue';
import BulkCreateProduct from "./BulkCreateProduct";
import PrintCatalog from "./PrintCatalog";
import SyncItemMaster from "./SyncItemMaster";
export default {
        components:{
            SyncItemMaster,
            BulkCreateProduct,
            PrintCatalog,
            ThatsInput,
            ThatsTable,
            mdbTabs,
            mdbBadge,mdbModalHeader,
            mdbModalBody, mdbModalFooter, mdbModal,
            DateRangePicker, mdbRow, mdbCol,
            ThatsDatePicker,
            mdbBtn,
            mdbDropdown,mdbDropdownToggle,mdbDropdownMenu,mdbDropdownItem,
            ThatsMultiSelect,
            ValidationObserver,ValidationProvider,ThatsMultiVueSelect,
            mdbSelect,
            mdbInput,
            ItemSoldToWindow,
            InvoiceForm,
            ThatsAutocomplete,
            BulkUpdateCountryOfOrigin,
            BulkPrintQrCode,
            RelatedDocumentStockHistoryDialog,
            RelatedDocumentPurchaseOrderDialog,
            RelatedDocumentIncomingContainerDialog,
            BulkUpdateItem,
            VueSlider,
            ThatsNumber,
            UpdateQuantity,
            SubCategoryForm
        },
    inject : ['brandProvider','categoryProvider','areaProvider','collectionProvider','filteredCategoryProvider','materialProvider','colourMasterProvider','sizeMasterProvider'],
    provide(){
        return {

        }
    },
    data(){
        return {
            endpoint : 'sub-category',
            bulkUpdate : 'status',
            viewTransactionLoading : 0,
            showModal:false,
            tableKey : 0,
            filterForm : {
                searchTerm : null,
                supplierCode : null,
                itemName : null,
                ItmGroup : "0",
                brands : "0",
                collection : "0",
                category : ["0"],
                materials : "ALL",
                colour : "ALL",
                attribute : "ALL",
                status : "all",
                website : "all",
                availability : "0",
                in_transit : "0",
                under_production : "0",
                range : [-9999,9999],
            },
            area : [],
            /* groups : [], */
           /*  brands : [], */
            category : [],
           /*  collections : [], */
            emailForm: {
                email:"",
                ItemCode: 'DEM001',
                ItemName: 'Demo Item 001',
                U_imgURL: 'https://media.thatsliving.com/public/uploads/image-jpeg/thumb-609a7771fdd9c7fa1c7b882f56b8398a-cncj-a302167-00.jpg', // Example static data
                Dimension: 'W26cm x H23cm'
            },
            itemWebsiteStatus : [
                {label : "ALL", value : "all"},
                {label : "YES", value : "1"},
                {label : "NO", value : "0"},
            ],

            itemStatus : [
                {label : "ALL", value : "all"},
                {label : "ACTIVE", value : "1"},
                {label : "DISCONTINUED", value : "0"},
            ],
            itemAvailabilityStatus : [
                {label : "ALL", value : "0"},
                {label : "AVAILABLE ONLY", value : "1"},
                {label : "ZERO AVAILABLE", value : "2"},
            ],
            itemInTransitStatus : [
                {label : "ALL", value : "0"},
                {label : "YES", value : "1"},
                {label : "NO", value : "2"},
            ],
            itemUnderProductionStatus : [
                {label : "ALL", value : "0"},
                {label : "YES", value : "1"},
                {label : "NO", value : "2"},
            ],
            slider:{
                    width: '100%',
                    min: 0,
                    max: 9999,
                    useKeyboard: true,
                    enableCross: true,
                    tooltipPlacement :'bottom',
                    tooltipFormatter : (v) => {
                        return `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    }
            },
            dots : [0,99],
            vueSliderCtr : 0

        }
    },
    created(){
    },

    mounted(){
        const self = this;

        // console.log("onmounted")
        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {
        ...mapActions('GoodsReceiptNoteModule',{getGoodReceiptNote : 'getRecord'}),
       ...mapActions('InventoryItemMasterModule',['getRecord','getTransactions']),
       ...mapMutations('SubCategoryModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters','setFormRecord']),
       ...mapMutations('InvoiceModule',{
            invoiceSetRecord : 'setFormRecord',
            invoiceStatusUpdate : 'setFormStatusUpdate',
            invoiceSetTblKey : 'setTblKey',
        }),
        ...mapActions('InvoiceModule',{
            invoiceGetRecord : 'getRecord',
            invoiceGetRecordSeries : 'getRecordSeries',
        }),
        ...mapActions('QuantityAdjustModule',['getCustomerQuotation']),

        rowClick(row){
            const self = this;

            const mergeFormData = {...this.initialFormObject,...JSON.parse(JSON.stringify(row))}

       /*      const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            }); */
            self.setFormStatusUpdate();
            self.setFormRecord(mergeFormData);
            self.$refs.subCategoryForm.openDialog();
        },

        clearFilterForm(){
            /* this.filterForm = {
                searchTerm : null,
                supplierCode : null,
                itemName : null,
                ItmGroup : "0",
                brands : "0",
                collection : "0",
                category : "0",
                materials : "ALL",
                colour : "ALL",
                attribute : "ALL",
                status : "all",
                website : "all",
                availability : "0",
                in_transit : "0",
                under_production : "0",
                range : [-999,999],
            } */
        },

        viewSoldTo(obj){
            this.$refs.itemSoldToWindow.triggerGetTransaction(obj)


        },
        viewRelatedDocument(obj){
            if(obj.reference == 'under_production_qty'){
                this.$refs.relatedDocumentDialogPurchaseOrder.triggerGetTransaction(obj.row)
            }
            if(obj.reference == 'in_transit_qty'){
                this.$refs.relatedDocumentDialogIncomingContainer.triggerGetTransaction(obj.row)
            }
            if(obj.reference == 'stock'){
                this.$refs.relatedDocumentDialogHistory.triggerGetTransaction(obj.row)
            }

        },
        openNewModal(){
            this.setFormStatusCreate();
            this.setEmptyForm();
           this.$refs.subCategoryForm.openDialog();
        },
        refreshTable(){
            // //alert(1)
            // this.tableKey++
            this.$refs.table.refreshTable()

        },

        itemSoldRowClickHandler(obj){
            const self = this;
            console.log("itemSoldRowClickHandler")
            console.log(obj)
            const form  = {...obj,items : [],attachment : [],invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
                    credit_note : {
                        total : 0,
                        status : null,
                        credit_note_reference : []
                    },
                    creditnotes : [],
                    credit_note_items : [],
                    cancellation : [],
                    cancellation_item : [],
                    refund : [],
                    refund_item : [],
                    exchange : [],
                    credit_memo : [],
                    exchange_item : [],
                    enable_push : false,
                    location : null,
                    }


                    if(!form.attr.hasOwnProperty('corporate')){
                            form.attr.corporate = false
                        }
                        if(!form.attr.hasOwnProperty('contact_person')){
                            form.attr.contact_person = {
                                name : null,
                                mobile : null,
                                attr : {

                                  address: null,
                                  address2 : {
                                      street : null,
                                      building : null,
                                      zone : null,
                                      area : null,
                                      city : null,
                                  },
                                  designation : null,
                                  email : null,
                                  first_name : null,
                                  middle_name : null,
                                  last_name : null,
                                  salutation : null
                                }
                              }
                        }
                    self.invoiceStatusUpdate()
                    self.invoiceSetRecord(form)
                    self.invoiceGetRecord({token_id : obj.token_id, series : obj.series})
                    this.invoiceSetTblKey(this.salesTblKey + 1);
                    this.$refs.invoiceForm.openDialog();

        },

        openCountryOfOriginDialog(){
            this.$refs.bulkUpdateCountryOfOrigin.openDialog();
        },
        openPrintStickerDialog(){
            this.$refs.bulkPrintQrCode.openDialog();
        },
        openBulkUpdateDialog(update){
            this.bulkUpdate = update;
            this.$refs.bulkUpdateItem.openDialog();
        },
        openBulkUpdateQuantityDialog(){
            this.$refs.updateQuantity.openDialog();
        },
        openBulkCreateQuantityDialog(){
            this.$refs.createProduct.openDialog();
        },
        openPrintCatalogDialog(){
            this.$refs.printCatalog.openDialog();
        },
        openSyncItemMasterDialog(){
            this.$refs.syncItemMaster.openDialog();
        },
        openEmailSend() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        tableSearchTerm(value){
           this.filterForm.searchTerm = value
        },
        ...mapActions('InventoryItemMasterModule',['sendEmailData']),
        onSubmitEmailForm() {
            this.sendEmailData(this.emailForm)
            this.showModal = false;
            this.emailForm.email = "";
        }


    },
    computed: {

        ...mapGetters('InvoiceModule',{
            salesTblKey: 'tblKey',
        }),

        ...mapGetters('SubCategoryModule',['getLoading', 'columns','initialFormObject']),
        brands : function(){
            const b = this.brandProvider();
           /*  return [...[
                {code : "0", brand : "ALL", parent : 0},
            ],...b] */


            if(["0"].includes(this.filterForm.ItmGroup)){
                return [...[
                {code : "0", brand : "ALL", parent : 0},
                ],...b];
            }else{
                const filteredData = b.filter((item) => item.parent === this.filterForm.ItmGroup,[])
                return [...[
                    {code : "0", brand : "ALL", parent : 0},
                ],...filteredData];
            }
        },
        groups : function(){
            const brandProviderData = this.brandProvider();
            const g = brandProviderData.filter((item) => item.parent == 0,[])
            return [...[
                {code : "0", brand : "ALL", parent : 0},
            ],...g]
        },

        categoryComputed : function(){
            return this.categoryProvider();
        },
        areaComputed : function(){
            return this.areaProvider();
        },

        collections : function(){
            const c = this.collectionProvider();
           /*  return [...[
                {mdbID : "0",name : "ALL", brand_id : 0},
            ],...c]; */

            if(["0"].includes(this.filterForm.brands)){
                return [...[
                {mdbID : "0",name : "ALL", brand_id : 0},
                ],...c];
            }else{
                const filteredData = c.filter((item) => item.brand_id == this.filterForm.brands,[])
                return [...[
                    {mdbID : "0",name : "ALL", brand_id : 0},
                ],...filteredData];
            }


        },

        filteredCategory : function(){

            const c = this.filteredCategoryProvider();

            if(["0"].includes(this.filterForm.brands)){
                return [...[
                {id : "0",name : "ALL", attributes : []},
                ],...c];
            }else{
                const filteredData = c.filter((item) => item.brands.includes(this.filterForm.brands),[])
                return [...[
                    {id : "0",name : "ALL", attributes : []},
                ],...filteredData];
            }
        },
        materials : function(){
            const self = this
            const c = this.materialProvider();

            if(["0"].includes(this.filterForm.brands) && ["0"].some(value => self.filterForm.category.includes(value))){
                return [...[
                {id : "0",name : "ALL", attributes : []},
                ],...c];
            }else{

                let fileteredData = [];
                if(!["0"].includes(this.filterForm.brands) && ["0"].some(value => self.filterForm.category.includes(value))){
                    fileteredData = c.filter((item) => item.brands.includes(this.filterForm.brands),[])
                }

                if(["0"].includes(this.filterForm.brands) && !["0"].some(value => self.filterForm.category.includes(value))){
                    fileteredData = c.filter((item) => item.category.some(value => self.filterForm.category.includes(value)),[])
                }

                if(!["0"].includes(this.filterForm.brands) && !["0"].some(value => self.filterForm.category.includes(value))){
                    fileteredData = c.filter((item) => item.brands.includes(this.filterForm.brands) && item.category.some(value => self.filterForm.category.includes(value)),[])
                }

                return [...[
                    {id : "0",name : "ALL", attributes : []},
                ],...fileteredData];


            }
        },
        colours : function(){
            const self = this
            const c = this.colourMasterProvider();

            console.log("colours")
            console.log(c)

            if(["0"].includes(this.filterForm.brands) && ["0"].some(value => self.filterForm.category.includes(value))){
                return [...[
                {id : "0",name : "ALL", attributes : []},
                ],...c];
            }else{

                let fileteredData = [];
                if(!["0"].includes(this.filterForm.brands) && ["0"].some(value => self.filterForm.category.includes(value))){
                    fileteredData = c.filter((item) => item.brands.includes(this.filterForm.brands),[])
                }

                if(["0"].includes(this.filterForm.brands) && !["0"].some(value => self.filterForm.category.includes(value))){
                    fileteredData = c.filter((item) => item.category.some(value => self.filterForm.category.includes(value)),[])
                }

                if(!["0"].includes(this.filterForm.brands) && !["0"].some(value => self.filterForm.category.includes(value))){
                    fileteredData = c.filter((item) => item.brands.includes(this.filterForm.brands) && item.category.some(value => self.filterForm.category.includes(value)),[])
                }
                return [...[
                    {id : "0",name : "ALL", attributes : []},
                ],...fileteredData];
            }

            // return []
        },

        sizes : function(){
            const self = this
            const s = this.sizeMasterProvider();

            console.log("sizeMasterProvider")
            console.log(s)

            if(["0"].includes(this.filterForm.brands) && ["0"].some(value => self.filterForm.category.includes(value))){
                return [...[
                {id : "0",name : "ALL", attributes : []},
                ],...s];
            }else{

                    let fileteredData = [];
                    if(!["0"].includes(this.filterForm.brands) && ["0"].some(value => self.filterForm.category.includes(value))){
                        fileteredData = s.filter((item) => item.brands.includes(this.filterForm.brands),[])
                    }

                    if(["0"].includes(this.filterForm.brands) && !["0"].some(value => self.filterForm.category.includes(value))){
                        fileteredData = s.filter((item) => item.category.some(value => self.filterForm.category.includes(value)),[])
                    }

                    if(!["0"].includes(this.filterForm.brands) && !["0"].some(value => self.filterForm.category.includes(value))){
                        fileteredData = s.filter((item) => item.brands.includes(this.filterForm.brands) && item.category.some(value => self.filterForm.category.includes(value)),[])
                    }
                    return [...[
                        {id : "0",name : "ALL", attributes : []},
                    ],...fileteredData];
                }
        },

    },

    watch : {
       /*  brandGroupComputed : function(value){
            console.log("watch brandGroupComputed")
            console.log(value)
            this.groups = [...[
                {code : "0", brand : "All", parent : 0},
            ],...value]
        }, */
       /*  brandComputed : function(value){
            console.log("watch brandComputed")
            console.log(value)
            this.brands = [...[
                {code : "0", brand : "All", parent : 0},
            ],...value]
        }, */
        categoryComputed : function(value){
          console.log("watch category")
          console.log(value)


          const uniqueCodesAndAreas = {};

            // Filter the array based on unique codes and areas
            const filteredData = value.filter(item => {
            const key = `${item.code}-${item.area}`;
            if (!uniqueCodesAndAreas[key]) {
                // If the code and area combination is not present, mark it as seen and keep the item
                uniqueCodesAndAreas[key] = true;
                return true;
            }
            // If the code and area combination is already seen, discard the item
            return false;
            },[]);

            console.log("filteredData");
            console.log(filteredData);
            console.log(value);

          this.category = [...[
                {code : "0", area : "",category : "All", attributes : []},
            ],...filteredData]
      },
      areaComputed : function(value){
          console.log("watch area")
          console.log(value)
          this.area = value
      },
     /*  collectionComputed : function(value){
          console.log("watch collectionComputed")
          console.log(value)
          this.collections = [...[
                {mdbID : "0",name : "ALL", brand_id : 0},
            ],...value]
      }, */

      "filterForm.ItmGroup" : function(){
            console.log("watch ItmGroup")
            console.log(this.filterForm.ItmGroup)

            // this.brands = this.brandProvider().filter((item) => item.parent == this.filterForm.ItmGroup,[])

           /*  if(this.filterForm.ItmGroup == "" || this.filterForm.ItmGroup == null){
                this.brands = this.brandProvider().filter((item) => item.parent == 0,[])
            }else{
                const brandArray = this.brandProvider().filter((item) => item.parent == this.filterForm.ItmGroup,[])
                this.brands = [...[
                                {code : "0", brand : "ALL", parent : 0},
                            ],...brandArray]

            } */

            this.filterForm.brands = "0"
            this.filterForm.collection = "0"
            this.filterForm.collection = "0"
            this.filterForm.materials = "ALL"
            this.filterForm.category = ["0" ]
            this.filterForm.colour = "ALL"
            this.filterForm.attribute = "ALL"
            // this.collections = [{mdbID : "0",name : "ALL", brand_id : 0}]

        },
        "filterForm.brands" : function(){
            console.log("watch brands")
            // console.log(this.filterForm.brands)
            // this.brands = this.brandProvider().filter((item) => item.parent == this.filterForm.ItmGroup,[])

    /*         if(this.filterForm.brands == "" || this.filterForm.brands == null){
                this.collections = this.collectionProvider();
            }else{
                const filteredData = this.collectionProvider().filter((item) => item.brand_id == this.filterForm.brands,[])

                this.collections = [...[
                    {mdbID : "0",name : "ALL", brand_id : 0},
                ],...filteredData]

            } */

            this.filterForm.collection = "0"
            this.filterForm.materials = "ALL"
            this.filterForm.category = ["0" ]
            this.filterForm.colour = "ALL"
            this.filterForm.attribute = "ALL"
        },

        "filterForm.availability" : function(value){
            console.log("filterForm.availability")
            console.log(value)

            if(value == "0"){
                this.slider.min = -9999
                this.slider.max = 9999

                this.filterForm.range = [-9999,9999]
            }

            if(value == "1"){
                this.slider.min = 1
                this.slider.max = 9999
                this.filterForm.range = [1,9999]
            }

            if(value == "2"){
                this.slider.min = -9999
                this.slider.max = 0
                this.filterForm.range = [-9999,0]
            }
            this.vueSliderCtr++
        }

    }
}


</script>
