/* eslint-disable no-prototype-builtins */
<template>
      <hsc-window-style-metal>
          <hsc-window v-bind:class="'costing-form height-auto'" title="BULK" :resizable="false" :left="x" :top="y" :width="width" :height="height" :minWidth="minWidth" :maxWidth="width" :minHeight="minHeight" :maxHeight="maxHeight" :isOpen.sync="dialogIsOpen" :closeButton="true" :zGroup="(group + 1)">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
            <div class="m-0">
                    <div ref="spreadsheetexport" class="new-item-table"></div> 
                    <div class="d-flex">
                      <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">SAVE</mdb-btn>
                              <mdb-btn color="grey" size="sm" @click="dialogIsOpen = false">Close</mdb-btn>
                      </template>
                  </div>
            </div>
            </ValidationObserver>
        </hsc-window>
       </hsc-window-style-metal>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn} from "mdbvue";

import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { MessageBox } from 'element-ui';

import jspreadsheet from '../../jsexcel/jexcel'
import country from '../generate-serial/country.js'


export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    },
  },
    components: {
        mdbBtn,
        ValidationObserver
  },
    
  data(){
    const prevValues = {};
    const self = this;
    return {

        inputValue : 0,
        validated : false,
        active: 0,
        tableData : [],
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        spreadsheetApplyLoading: false,
        spreadsheetexport: null,
        exportTableOptions : {
          copyCompatibility: false,
            download : false,
            csvFileName : 'material',
            allowDeleteRow : true,
            allowInsertRow : true,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : false,
            csvHeader:true,
            tableWidth : '800px',
            tableHeight : 'calc(100vh - 85px)',
            tableOverflow : true,
            data:[],

             minDimensions:[2,50],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 0,
             columns: [],
             contextMenu: function(obj, x, y, e) {
                var items = [];              

                  if(y !== null){
                      
                      if (obj.options.allowInsertRow == true) {
                          const colData = obj.getColumnData(0)

                              if(colData.length == (parseInt(y)+1)){
                              
                              items.push({
                                  title:obj.options.text.insertANewRowAfter,
                                  onclick:function() {
                                      obj.insertRow(1, parseInt(y));
                                  }
                              });
                            }

                      }
                  }

                var colId = obj.getValueFromCoords(0, y);
                if (obj.options.allowDeleteRow == true && (colId == null || colId == "")) {
                    items.push({
                        title:obj.options.text.deleteSelectedRows,
                        onclick:function() {
                            obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                        }
                    });
                }
                return items;
             },

             onblur: function(instance){

             },
             onchange: function(instance, cell, x, y, value){
              
             },
            onafterchanges(){
              //  self.updateStyleOnChange()
            },
            updateTable: function (instance, cell, col, row, val, label, cellName) {
              if((row % 2) == 1){
                  cell.parentNode.style.backgroundColor = '#FAFAFA';
              }
            },
            onbeforechange: function(instance, cell, x, y, value) {
                var cellId = `${x},${y}`;
                if(cell){
                  if(x == 1){

                    var prevValuex = prevValues[cellId];
                    if (prevValuex === null || prevValuex === undefined) {
                      var nextColumnX = parseInt(x) + 1; 
                      instance.jexcel.setValueFromCoords(nextColumnX, y, 1, true); 
                    }
                    prevValues[cellId] = instance.jexcel.getValueFromCoords(x, y);
                  }
                }
            }, 
       },

       localData : []
    }
  },
    

  created : function() {

    this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.width = window.innerWidth;
        this.exportTableOptions.tableWidth = `${window.innerWidth - 3}px`
  },

  mounted(){
        const self = this
        this.countryData = country

        
  },

  updated(){

  },
  methods: {

    ...mapActions('MaterialModule',['createOrUpdate','getAllMaterials']),
    ...mapMutations('MaterialModule',['setAllMaterials']),
    ...mapActions('CodeGeneratorAreaModule',['getArea']),
    ...mapActions('CodeGeneratorCategoryModule',['createUpdateRecord','getRecords']),

    updateStyleOnChange(){
      const self = this


        const getData = self.spreadsheetexport.getData();
                const columns = [
                                      'id',
                                      'name',
                                      'status'
                                    ];
                    const newRow = getData.map((item) => {
                      const rowArray = {};
                      columns.forEach((col,index) => {
                            if(['id'].includes(col)){
                                if(isNaN(parseInt(item[index]))){
                                  rowArray[col] = null
                                }else{

                                  rowArray[col] = parseInt(item[index]);
                                }
                            }else{
                              rowArray[col] = item[index]
                            }
                      })

                      return {...rowArray}
                  },[]);


                  const codeColumnData= getData.filter((item) => {
                    return item[1] !== ''
                  }).map((item)=>{
                return `${item[1]}`
              })



                //  const codeColumnData = self.spreadsheetexport.getColumnData(1);

                let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index && item !== '')
                const duplicateValues = [...new Set(findDuplicates(codeColumnData))]
                  self.spreadsheetexport.destroy();
                  self.exportTableOptions.data = JSON.parse(JSON.stringify(newRow))
  
                  self.renderTable();

                    codeColumnData.forEach((element,i) => {
                      if(duplicateValues.includes(element)){
                            self.spreadsheetexport.setStyle(`A${(i+1)}`,'background-color','pink');
                            self.spreadsheetexport.setStyle(`B${(i+1)}`,'background-color','pink');
                            self.spreadsheetexport.setStyle(`C${(i+1)}`,'background-color','pink');
                      }
                    }); 
    },

    resetForm(){
      
      },
    openDialog(){
      const self = this;

      
      

      this.dialogIsOpen = true;
    },
    closeDialog(){
      this.dialogIsOpen = false
    },
    getActiveTabIndex(){

    },
    async onSubmitForm(){
       const self = this;

       const getJsonData = self.spreadsheetexport.getData();
       console.log("getJsonData");
       console.log(getJsonData);
       const getData = getJsonData.map((item, index) => {
         return {
           id : item[0],
           name : item[1],
           status : item[2],
           row : index + 1
         }
       });
       console.log("getData");
       console.log(getData);
       
            const isValid = self.$refs.observer.validate();

         
            if(isValid){
              const codeColumnData= getData.filter((item) => {
                return item.name !== ''
              }).map((item)=>{
                return `${item.name}`
              })

              getData.forEach((element, index) => {
                if(index % 2 == 0){
                  self.spreadsheetexport.setStyle(`A${(index+1)}`, 'background-color', '#f9f9f9');
                  self.spreadsheetexport.setStyle(`B${(index+1)}`, 'background-color', '#f9f9f9');
                  self.spreadsheetexport.setStyle(`C${(index+1)}`, 'background-color', '#f9f9f9');
                }else{
                  self.spreadsheetexport.setStyle(`A${(index+1)}`, 'background-color', '#ffffff');
                  self.spreadsheetexport.setStyle(`B${(index+1)}`, 'background-color', '#ffffff');
                  self.spreadsheetexport.setStyle(`C${(index+1)}`, 'background-color', '#ffffff');
                }
                
              })

                let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index && item !== '')
                const duplicateValues = [...new Set(findDuplicates(codeColumnData))]

                  if(duplicateValues.length > 0){
                    MessageBox.alert('Name must be unique', 'Error', {
                      type : 'error'
                    })

                    this.updateStyleOnChange()
                  }else{

                   

                    const existingData = this.localData.map((item) => {
                      return {
                        id : item.id,
                        name : item.name,
                        status : item.status
                      }
                    });

                    const newData = getData.filter((item) => {
                      return item.name !== '' || item.status !== ''
                    },[]).map((item) => {
                      const rowArray = item;
                      /* [  'id','name','status','row' ].forEach((col,index) => {
                            if(['id'].includes(col)){
                                if(isNaN(parseInt(item[index]))){
                                  rowArray[col] = null
                                }else{
                                  rowArray[col] = parseInt(item[index])
                                }
                              }else{
                                rowArray[col] = item[index]
                              }
                        }); */

                        const existing = existingData.find((element) => element.id === rowArray.id);
                        if(existing){
                          if(existing.name !== rowArray.name || existing.status !== rowArray.status){
                            return {...rowArray, updated : true, new_record : false, send : true}
                          }else{
                            return {...rowArray, updated : false, new_record : false, send : false}
                          }
                        }else{
                          if(rowArray.name !== null){
                            return {...rowArray, updated : false, new_record : true, send : true}
                          }
                        }

                      },[]).filter((item) => !!item && item.send);




                      console.log("newData");
                      console.log(newData);

                      const done = []

                      if(newData.length > 0){
                        this.loading = true
                         await Promise.all(newData.map(async (item) => {
                            try {
                              const response = await this.createOrUpdate({...item})
                              done.push({...response.data, success : true, send : false, new_record : false, updated : false})
                              if(response.hasOwnProperty('index')){                               
                                const i = parseInt(response.index) - 1;
                                self.setRowSuccess(i + 1)
                                self.setRowValue(i, response.data)
                                // self.localData.push(response.data)
                               /*  const existing = self.localData.find((element) => element.id === response.data.id);
                                if(existing){
                                  self.$set(self.localData, self.localData.indexOf(existing), response.data)
                                }else{
                                  self.localData.push(response.data)
                                } */
                              }
                            } catch (error) {
                              const errorErrorResponse = error.response.data
                              console.log(errorErrorResponse)
                              if(errorErrorResponse.hasOwnProperty('index')){
                                const i = parseInt(errorErrorResponse.index);
                               
                                self.setRowError(i)
                              } 
                              done.push({...item, index : item.row, success : false, send : false, new_record : false, updated : false})
                            }finally{
                              this.loading = false
                              console.log("finaly done")
                              console.log(done)
                            }
                         }))
                      }


                  }        
            }

            
        },
        setRowSuccess(i){
            this.spreadsheetexport.setStyle(`A${i}`,'background-color','#04b65d', true);
            this.spreadsheetexport.setStyle(`B${i}`,'background-color','#04b65d', true);
            this.spreadsheetexport.setStyle(`C${i}`,'background-color','#04b65d', true); 
        },
        setRowError(i){
            this.spreadsheetexport.setStyle(`A${i}`,'background-color','pink', true);
            this.spreadsheetexport.setStyle(`B${i}`,'background-color','pink', true);
            this.spreadsheetexport.setStyle(`C${i}`,'background-color','pink', true); 

        },

        setRowValue(i, obj){
            this.spreadsheetexport.setValueFromCoords(0,i, obj.id, true); 
            this.spreadsheetexport.setValueFromCoords(1,i, obj.name, true); 
            this.spreadsheetexport.setValueFromCoords(2,i, obj.status, true); 
        },

        createPopupTable(exportTableOptions){
            console.log(exportTableOptions)
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
           
        },
        reCreatePopupTable(exportTableOptions){

            // exportTableOptions.data = this.areaData


            console.log("before assign")
            console.log(exportTableOptions)
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
           
        },

        async recreateSpreadsheet(){
             this.spreadsheetexport.destroy();

            await this.renderTable();
        },
        fnExportXls(){
            this.spreadsheetexport.download(this.$refs.spreadsheetexport)
        },
        renderTable(){
            const self = this;
            this.exportTableOptions.columns = [

             
                  {
                    type : 'text',
                    width : '100px',
                    align : 'left',
                    name : 'id',
                    title : 'ID',
                    readOnly : true
                  },
                  {
                    type : 'text',
                    align : 'left',
                    name : 'name',
                    title : 'NAME',
                    width : '400px',
                  },
                  { type:'dropdown',width : '200px',name : 'status', title:'STATUS', source:[ {'id':'0', 'name':'Inactive'}, {'id':'1', 'name':'Active'} ] },
                 
            ];

            
                this.createPopupTable(this.exportTableOptions);
        },



        fnExport(doc){

          window.open(`${this.$route.path}/reports/${doc.id}`, "_blank");
          
        },

        async getAllRecords(){
          const self = this
          self.formLoading = true
          await this.getAllMaterials().then((response) => {
            self.localData = response
          })

          self.spreadsheetexport.setData(self.localData)
          self.formLoading = false
        }

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
      ...mapGetters('MaterialModule', ['allMaterials','allMaterialsLoading']),
      tblKey : function(){
        return 1;
      }
    
  },

  watch: {
      /* 'allMaterials' : function(value,oldVal){
      

          if(oldVal.length <= 0){

            console.log("this.exportTableOptions.data.length <= 0")
            this.exportTableOptions.data = value
            this.exportTableOptions.minDimension = [3,value.length + 5]
            if(this.spreadsheetexport != null){
              this.spreadsheetexport.destroy();
            }
            
            this.renderTable();
          }else{
            console.log("else")
            this.exportTableOptions.data = value
            this.exportTableOptions.minDimension = [3,value.length + 5]

              

              if(this.dialogIsOpen){
                  this.spreadsheetexport.setData(value)
                }

          }
      }, */
       
      'dialogIsOpen' : async function(newState,old) {

       const self = this;
              if(!newState){
               this.spreadsheetexport.destroy();
                this.resetForm();
                this.setAllMaterials([]);
                return
              }else{
                
                  self.exportTableOptions.data = []
                  self.renderTable();
                  await self.getAllRecords();
              }
        },

  


  }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #dddddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}


</style>