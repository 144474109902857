import { ApiHttpService } from '../../services'
export const CategoryMasterModule = {
    namespaced: true,
    state: {
        request: null,
        loading : false,
        categories : [],
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {

            if(state.request != null){
                state.request.cancel()
            }
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },

        loadingTrue(state) {
            state.loading = true
        },
        loadingFalse(state) {
            state.loading = false
        },
        setCategories(state, categories) {
            state.categories = categories
        },
       
    },
    actions : {

        async filter({ commit}) {
            return await new Promise((resolve, reject) => {

                    commit('loadingTrue')
                    const params = {}
                    const configParams = {
                        req : "CategoryMasterModule/req",
                        loadingTrue : "CategoryMasterModule/loadingTrue",
                        addRequest : "CategoryMasterModule/addRequest",
                        cancelRequest : "CategoryMasterModule/cancelRequest",
                        clearOldRequest : "CategoryMasterModule/clearOldRequest"
                    }

                    ApiHttpService.get("category-master/filter", params, configParams).then((response) => {
                        resolve(response.data)
                        commit('setCategories', response.data)
                        commit('loadingFalse')
                    }).catch(error => {
                        commit('loadingFalse')
                        reject(error)
                    })

            })
        },


    },
    getters: {
        loading : state => state.loading,
        req: state => state.request,
        categories: state => state.categories
    }
}



