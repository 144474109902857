
<template>
    <div class="customervendor-container">
        <ThatsTable title="Materials" ref="table" v-bind:class="{'show-on-table' : showOnWindow}" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns">
            <template #customBtns>
                <mdb-btn color="elegant" icon="star-of-life" size="sm" class="mr-auto mt-0" @click="openBulkWindow">Bulk</mdb-btn>
            </template>
        </ThatsTable>
        <MaterialForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></MaterialForm>
        <BulkForm ref="bulkForm" @success="refreshTable"></BulkForm>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import MaterialForm from './MaterialForm'
import BulkForm from './BulkForm'
import ThatsTable from '../../components/thats/Table'
import {mdbBtn} from 'mdbvue'
export default {
    props : {
        showOnWindow : {
            type :  Boolean,
            default : false,
        }
    },
    data(){
        return {
            endpoint : 'material/data',
            area : []
        }
    },
    components: {
        MaterialForm,
        ThatsTable,
        BulkForm,
        mdbBtn
    },

    methods: {

        ...mapMutations('MaterialModule',[ 'SetFormRecord', 'SetFormStatusUpdate','SetFormStatusCreate']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })
            this.closeWindow()
        },
        OpenUpdateModal(obj){

             if(!this.showOnWindow){
                this.SetFormStatusUpdate()
                this.SetFormRecord(obj)
                this.openWindow()
            }else{
                this.$emit('openUpdateModal')
            }
        },
        openWindow(){
            if(!this.showOnWindow){
                
                this.$refs.dialogForm.openDialog()
            }else{
                this.$emit('openModal')
            }
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },

        openBulkWindow(){
        
            this.$refs.bulkForm.openDialog()
        },

    },
    computed: {
        ...mapGetters('MaterialModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ]),
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .table-row-input{
      width : 100%;
  }
</style>