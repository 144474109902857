
<template>
    <div class="customervendor-container">
        <ThatsTable title="Category" ref="table" v-bind:class="{'show-on-table' : showOnWindow}" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns">
            <template #custom-culumns="{columns, getValue,handleClick}">
            <el-table-column label="Order" width="60">
                <template slot-scope="scope">
                    <input type="text" v-model="scope.row.order" @change="updateOrder(scope.$index, scope.row)" class="table-row-input" />
                </template>
            </el-table-column>
                    

            <template v-for="col in columns">
                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else>
                                {{getValue(scope,col.field)}}
                                <div v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></div>
                            
                            </template>
                        </template>
                        
                    </el-table-column>
                </template>
            </template>
            <template #filter="{dispatchFilter}">
                <CategoryFilter @filter="dispatchFilter" :area="area"></CategoryFilter>
            </template>

            <template #customBtns>
                <mdb-btn color="elegant" icon="star-of-life" size="sm" class="mr-auto mt-0" @click="openBulkWindow">Bulk</mdb-btn>
            </template>

        </ThatsTable>
        <CategoryForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></CategoryForm>
        <BulkForm ref="bulkForm" @success="refreshTable"></BulkForm>
    </div>
</template>
<script>
import { mapGetters, mapMutations,mapActions } from 'vuex';
import CategoryForm from './CategoryForm'
import CategoryFilter from './CategoryFilter'
import ThatsTable from '../../components/thats/Table'
import BulkForm from './BulkForm'
import {mdbBtn} from 'mdbvue'
export default {
    props : {
        showOnWindow : {
            type :  Boolean,
            default : false,
        }
    },
    data(){
        return {
            endpoint : 'category/data',
            area : []
        }
    },
    components: {
        CategoryFilter,
        CategoryForm,
        ThatsTable,
        BulkForm,
        mdbBtn
    },
    created(){
        // this.GetArea()
        // this.GetBrands()
        //this.refreshTable();
        this.GetArea().then(response => {
              const area = [];
            response.forEach(element => {
              area.push({label : element.label,value : `${element.value}`})
            });
            this.area = area
          });

    },
    methods: {
        // 
        ...mapActions('AreaModule',[ 'GetArea' ]),
        ...mapActions('CategoryModule',[ 'UpdateOrder','DeleteRecord']),
        ...mapMutations('CategoryModule',[ 'SetFormRecord', 'SetFormStatusUpdate','SetFormStatusCreate']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })
            this.closeWindow()
        },
        OpenUpdateModal(obj){

             if(!this.showOnWindow){
                this.SetFormStatusUpdate()
                this.SetFormRecord(obj)
                this.openWindow()
            }else{
                this.$emit('openUpdateModal')
            }
        },
        openWindow(){
            if(!this.showOnWindow){
                
                this.$refs.dialogForm.openDialog()
            }else{
                this.$emit('openModal')
            }
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },

        updateOrder(index,row){
            this.UpdateOrder(row)
        },

        openBulkWindow(){
            this.$refs.bulkForm.openDialog()
        },

    },
    computed: {
        ...mapGetters('CategoryModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ]),
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .table-row-input{
      width : 100%;
  }
</style>