import { ApiHttpService } from '../../services'
export const CountryModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        data: {
            columns: [],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},

        countries: [],


    },
    actions: {

        async getCountries({commit},params) {
            return await new Promise((resolve,reject) => {
                ApiHttpService.getRequest('countries', params).then((response) => {
                    console.log("response countries",response.data)
                    commit('setCountries',response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

    },
    mutations: {
        setCountries: (state, payload) => {
            state.countries = payload
        }

    },
    getters: {
        countries: state => state.countries,
    }
}


