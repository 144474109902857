<template>
<ValidationObserver tag="form" ref="subCategoryFormObserver" v-slot="{passes}" @submit.prevent="onFormSubmit()">
  <el-dialog
      title="SUB CATEGORY"
      :visible.sync="formModal"
       
      center class="dialog-small-fixed-width">

        <ThatsInput :show_error="showError"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Name" vid="name" name="name" v-model="formData.name" :validated="validated" :rules="'required'" size="sm" />
        <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Category" v-model="formData.category_id" :reduce="category => category.code" :reduceLabel="'name'" :options="categoryComputed" :rules="'required'"></ThatsMultiSelect>
        <mdb-row >
          <mdb-col offset="3" col="9" class="pl-0">
              <ThatsCheckbox :label="'Active'" vid="category-hidden-status" orientation="inline" name="category-hidden-status" :data="[]" v-model="formData.status" :validated="validated" :rules="''" size="sm" :multiple="false" />
          </mdb-col>
        </mdb-row>
      <template slot="footer">
        <template v-if="formLoading">
          <mdb-btn color="elegant" size="sm" disabled class="m-0">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </mdb-btn>
        </template>
        <template v-else>
          <mdb-btn color="elegant"  @click="passes(onFormSubmit)" size="sm" class="m-0">SAVE</mdb-btn>
        </template>
        <mdb-btn @click="formModal = false" color="grey" size="sm" class="m-0">Cancel</mdb-btn>
      </template>
    </el-dialog>
  </ValidationObserver> 
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import { mdbBtn, mdbRow, mdbCol } from 'mdbvue';
import { ThatsInput, ThatsMultiSelect, ThatsCheckbox } from '../../components/thats/Thats'
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'SubCategoryForm',
  components: {
    ValidationObserver,
    mdbBtn,
    mdbRow,
    mdbCol,
    ThatsInput,
    ThatsMultiSelect,
    ThatsCheckbox
  },
  data() {
    return {
      formModal: false,
      formData : null,
      validated : false,
      showError : false,
      formLoading : false
    };
  },
  inject : ['categoryProvider'],

  created() {
    this.formData = JSON.parse(JSON.stringify(this.initialFormObject))
  },
  methods: {

    ...mapActions('SubCategoryModule', ['storeRecord','updateRecord']),
    onFormSubmit() {

      const self  = this;
      this.$refs.subCategoryFormObserver.validate().then((success) => {

        console.log("success",  success)
        console.log("self.formStatus",  self.formStatus)
        if (success) {
          self.formLoading = true;
          if(self.formStatus == 'create') {
            self.storeRecord(self.formData).then(() => {
              self.formModal = false;
              self.formLoading = false;
              self.$ThatsNotify.success({
                                    message : "Record was successfully created"
                                })
              self.$emit('success')
            }).catch(() => {
              self.formLoading = false;
            })
          } else {
            console.log("self.formData",  self.formData)
            self.updateRecord(self.formData).then((res) => {
              self.$ThatsNotify.success({
                message : "Record was successfully updated"
              })
              self.formModal = false;
              self.formLoading = false;
              self.$emit('success')
            }).catch(() => {
              self.formLoading = false;
            })
          }
        }
      });
    },

    openDialog() {
      this.formModal = true;
    },

    closeDialog() {
      this.formModal = false;
    },
  },
  computed: {
    ...mapGetters('SubCategoryModule',{
      initialFormObject : 'initialFormObject',
      formRecord : 'formRecord',
      formStatus : 'formStatus',
    }),

    ...mapGetters('CategoryMasterModule', ['categories']),

    categoryComputed() {
      return this.categories.map(category => ({
        code : category.id,
        name : category.name
      }))
    }
  },

  watch : {
    formRecord : {
      handler() {
        this.formData = JSON.parse(JSON.stringify(this.formRecord))
      },
      deep : true
    }
  }
};
</script>