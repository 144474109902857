/* eslint-disable no-prototype-builtins */
<template>
  <div>
      <hsc-window-style-metal>
          <hsc-window v-bind:class="'costing-form height-auto'" title="BULK" :resizable="false" :left="x" :top="y" :width="width" :height="height" :minWidth="minWidth" :maxWidth="width" :minHeight="minHeight" :maxHeight="maxHeight" :isOpen.sync="dialogIsOpen" :closeButton="true" :zGroup="(group + 1)">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
            <div class="m-0">
                    <div ref="spreadsheetexport" class="new-item-table"></div> 
                    <div class="d-flex">
                      <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">SAVE</mdb-btn>
                              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitSync)">SYNC</mdb-btn>
                              <mdb-btn color="grey" size="sm" @click="dialogIsOpen = false">Close</mdb-btn>
                      </template>
                  </div>
            </div>
            </ValidationObserver>
        </hsc-window>
       </hsc-window-style-metal>
       <el-dialog
        title="MERGE CATEGORIES"
        :visible.sync="mergeModalVisible"
        width="40%"
        center>
        <template v-if="mergeTo !== null">
        <div class="alert alert-info" role="alert">
         Select the categories you want to merge to <strong>{{ mergeTo.name }}</strong>.
        </div>
        </template>
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          >
          <el-table-column
            type="selection"
            width="40">
          </el-table-column>
          <el-table-column
            label="NAME"
            >
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column
            label="LINKED ITEMS"
            >
            <template slot-scope="scope">{{ scope.row.linked_items }}</template>
          </el-table-column>
         
          <el-table-column
            label="STATUS"
            show-overflow-tooltip  width="100">
            <template slot-scope="scope">{{ categoryStatus[scope.row.status] }}</template>
          </el-table-column>
        </el-table>
        
        <span slot="footer" class="dialog-footer text-left">
          <mdb-btn @click="mergeModalVisible = false" size="sm" color="grey">Cancel</mdb-btn>
          <mdb-btn type="primary" @click="confirmMerge" size="sm" color="elegant" :disabled="tableSelectedData.length === 0">Confirm</mdb-btn>
        </span>
      </el-dialog>
  </div>
</template>
<script>
import { mdbClickOutside,mdbBtn} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions, mapMutations } from 'vuex'
import { MessageBox, Message } from 'element-ui';
import jspreadsheet from '../../jsexcel/jexcel';

export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    },
  },
    components: {
        mdbBtn,
        ValidationObserver
  },
    
  data(){
    const self = this
    const prevValues = {};
    return {
      mergeModalVisible : false,
        inputValue : 0,
        validated : false,
        active: 0,
        area : [],
        brands : [],
        tableData : [],
        tableSelectedData : [],
        mergeTo : null,
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        spreadsheetApplyLoading: false,
        spreadsheetexport: null,
        exportTableOptions : {
          copyCompatibility: false,
            download : false,
            csvFileName : 'material',
            allowDeleteRow : true,
            allowInsertRow : true,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : false,
            csvHeader:true,
            tableWidth : '800px',
            tableHeight : 'calc(100vh - 85px)',
            tableOverflow : true,
            data:[],

             minDimensions:[2,50],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 0,
             columns: [],
             contextMenu: function(obj, x, y, e) {
                var items = [];              

                  /* if(y !== null){ */
                      
                      if (obj.options.allowInsertRow == true) {
                        /*   const colData = obj.getColumnData(0)

                              if(colData.length == (parseInt(y)+1)){
                              
                              } */
                              items.push({
                                  title:obj.options.text.insertANewRowAfter,
                                  onclick:function() {
                                      obj.insertRow(1, parseInt(y));
                                  }
                              });

                     /*  } */
                  }

                var colId = obj.getValueFromCoords(0, y);
                if (obj.options.allowDeleteRow == true && (colId == null || colId == "")) {
                    items.push({
                        title:obj.options.text.deleteSelectedRows,
                        onclick:function() {
                            obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                        }
                    });
                }
                return items;
             },

             onblur: function(instance){

             },
             onchange: function(instance, cell, x, y, value){
                
             },
            onafterchanges(){
              //  self.updateStyleOnChange()
              self.findDuplicateCode();
              
            },
            updateTable: function (instance, cell, col, row, val, label, cellName) {
              if((row % 2) == 1){
                  cell.parentNode.style.backgroundColor = '#FAFAFA';
              }
              if(col == 6){

                const colValue = instance.jexcel.getValueFromCoords(4, row);
                const colId = instance.jexcel.getValueFromCoords(0, row);
                const rowValue = instance.jexcel.getRowData(row);

                if(colValue != null && colValue != "" && colValue == 1 && colId != null && colId != ""){

                    const existingButton = cell.querySelector('.merge-btn');
                    if(existingButton){
                      existingButton.remove();
                    }
                    const button = document.createElement("button");
                    button.innerHTML = "MERGE";
                    button.type = "button";
                    button.classList.add("btn", "btn-elegant","btn-sm","m-0","merge-btn","p-1");
                    button.onclick = function(){
                      self.mergeModal(rowValue);
                    };
                  cell.appendChild(button);
                }else{
                  cell.innerHTML = "";
                }
              }
            },
            onbeforechange: function(instance, cell, x, y, value) {
                var cellId = `${x},${y}`;
                if(cell){
                  if(x == 1){

                    var prevValuex = prevValues[cellId];
                    if (prevValuex === null || prevValuex === undefined) {
                      var nextColumnX = parseInt(x) + 3; 
                      instance.jexcel.setValueFromCoords(nextColumnX, y, 1, true); 
                    }
                    prevValues[cellId] = instance.jexcel.getValueFromCoords(x, y);
                  }
                }
            }, 
       },

       localData : []
    }
  },
    

  created : function() {

    this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.width = window.innerWidth;
        this.exportTableOptions.tableWidth = `${window.innerWidth - 3}px`
  },
  mounted() {
    const self = this
    this.GetArea().then(response => {
          const area = [];
        response.forEach(element => {
          area.push({name : element.label,id : `${element.value}`})
        });
        self.area = area
    });
   
  },
  methods: {
    ...mapActions('AreaModule',[ 'GetArea' ]),
    ...mapActions('CategoryModule',['createOrUpdate','getAllRecords','mergeCategories']),
    ...mapMutations('CategoryModule',['setAll','setAllLoading']),

    mergeModal(row){
      const self = this
      this.tableData = [];
      this.$nextTick(() => {
        const columns = [
          'id',
          'name',
          'ar',
          'area',
          'status',
          'linked_items',
        ];
        const mappedObject = row.reduce((acc, value, index) => {
          if(value!==''){
            acc[columns[index]] = value;
          }
          return acc;
        }, {});
        self.mergeTo = mappedObject
        self.tableData = self.localData.filter((item) => {
          return item.id != row[0] && item.id != null && item.area == row[3]
        },[])
      })
      this.mergeModalVisible = true
    },

    async confirmMerge(){
      const self = this
      // this.mergeModalVisible = false
        MessageBox.confirm('You are about to merge the categories and move all linked items to '+this.mergeTo.name+' category. This action is irreversible.', 'MERGE CATEGORIES', {
        confirmButtonText: 'CONTINUE?',
        cancelButtonText: 'CANCEL',
        type: 'warning'
        }).then(async () => {
          // self.mergeCategories(self.mergeTo.id, self.tableData.map((item) => item.id))

            self.mergeCategories({ merge_to : self.mergeTo.id, merge_from : self.tableSelectedData.map((item) => item.id)}).then((response) => {
                self.mergeModalVisible = false
                self.triggerGetAllRecords()
            }).catch((error) => {
                self.mergeModalVisible = false
            })

            /* await Promise.all(self.tableSelectedData.map(async (item) => {
                    try {
                      
                      const response = await this.mergeCategories({...item, attr : { 
                        variation_label : null,
                        attributes : [],
                        locale : {
                          arabic : item.ar,
                          poland : null,
                        }
                      }, brands : [16]})
                      done.push({...response.data, success : true, send : false, new_record : false, updated : false})
                      if(response.hasOwnProperty('index')){                               
                        const i = parseInt(response.index) - 1;
                        self.setRowSuccess(i + 1)
                        self.setRowValue(i, response.data)
                      
                      }
                    } catch (error) {
                      const errorErrorResponse = error.response.data
                      console.log(errorErrorResponse)
                      if(errorErrorResponse.hasOwnProperty('index')){
                        const i = parseInt(errorErrorResponse.index);
                        self.setRowError(i)
                      } 
                      done.push({...item, index : item.row, success : false, send : false, new_record : false, updated : false})
                    }finally{
                  this.formLoading = false
                  console.log("finaly done")
                  console.log(done)
                }
              })) */
        })

        console.log("self.mergeTo");
        console.log(this.mergeTo);
        console.log("self.tableData");
        console.log( self.tableSelectedData);
     

    },

    handleSelectionChange(v){
      this.tableSelectedData = v
    },
    updateStyleOnChange(){
      const self = this
        const getData = self.spreadsheetexport.getData();
                const columns = [
                                      'id',
                                      'name',
                                      'ar',
                                      'area',
                                      'status',
                                      'linked_items',
                                    ];
                    const newRow = getData.map((item) => {
                      const rowArray = {};
                      columns.forEach((col,index) => {
                            if(['id'].includes(col)){
                                if(isNaN(parseInt(item[index]))){
                                  rowArray[col] = null
                                }else{

                                  rowArray[col] = parseInt(item[index]);
                                }
                            }else{
                              rowArray[col] = item[index]
                            }
                      })

                      return {...rowArray}
                  },[]);


                  const codeColumnData= getData.filter((item) => {
                        return item[1] !== ''
                      }).map((item)=>{
                    return `${item[1]}`
                  })

              let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index && item !== '')
                const duplicateValues = [...new Set(findDuplicates(codeColumnData))]
                  self.spreadsheetexport.destroy();
                  self.exportTableOptions.data = JSON.parse(JSON.stringify(newRow))
  
                  self.renderTable();

                    codeColumnData.forEach((element,i) => {
                      if(duplicateValues.includes(element)){
                            self.setRowError(i + 1);
                      }
                    }); 
    },


    findDuplicateCode(){
      const self = this 
      const getData = self.spreadsheetexport.getData();
      const codeColumnData= getData.filter((item) => {
                        return item[1] !== ''
                      }).map((item)=>{
                    return `${item[1]}`
                  })

          let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index && item !== '')
            const duplicateValues = [...new Set(findDuplicates(codeColumnData))]
          codeColumnData.forEach((element,i) => {
            if(duplicateValues.includes(element)){
              self.setRowError(i+1);
            }
          }); 
          
    },
    resetForm(){
      
      },
    openDialog(){
      this.dialogIsOpen = true;
    },
    closeDialog(){
      this.dialogIsOpen = false
    },
    async onSubmitSync(){
      const self = this;
      const getJsonData = self.spreadsheetexport.getData();
      const getData = getJsonData.map((item, index) => {
         return {
           id : item[0],
           name : item[1],
           ar : item[2],
           area : item[3],
           status : item[4],
           linked_items : item[5],
           row : index + 1
         }
       });


       const isValid = self.$refs.observer.validate();

                
        if(isValid){
          const codeColumnData= getData.filter((item) => {
            return item.name !== ''
          }).map((item)=>{
            return `${item.name}`
          })

          getData.forEach((element, index) => {
            self.setStripe(index + 1)
          })

            const areaColumnData= getData.filter((item) => {
              return item.area === '' || item.area === null || item.ar === '' || item.ar === null
            }).map((item)=>{
              return `${item.area}`
            })

            if(areaColumnData.length > 0){
              MessageBox.alert('Arabic and Area columns are required.', 'Error', {
                type : 'error'
              })

              getJsonData.forEach((item, index) => {

                if(item[3] === '' || item[2] === null){ 
                  self.setRowError(index+1);
                }else{
                  self.setStripe(index + 1)
                }
              })
          
              return false
            }

            let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index && item !== '')
            const duplicateValues = [...new Set(findDuplicates(codeColumnData))]

              if(duplicateValues.length > 0){
                MessageBox.alert('Name must be unique', 'Error', {
                  type : 'error'
                })

                this.updateStyleOnChange();
                return false
              }

              const existingData = this.localData.map((item) => {
                      return {
                        id : item.id,
                        name : item.name,
                        ar : item.ar,
                        area : item.area,
                        status : item.status,
                        linked_items : item.linked_items
                      }
                    });

              const newData = getData.filter((item) => {
                      return item.name !== '' || item.status !== '' || item.area !== '' || item.ar !== ''
                    },[]).map((item) => {
                      const rowArray = item;
                        const existing = existingData.find((element) => element.id === rowArray.id);
                        if(existing){
                          if(existing.name !== rowArray.name || existing.status !== rowArray.status || existing.area !== rowArray.area || existing.ar !== rowArray.ar){
                            return {...rowArray, updated : true, new_record : false, send : true}
                          }else{
                            return {...rowArray, updated : false, new_record : false, send : true}
                          }
                        }else{
                          if(rowArray.name !== null){
                            return {...rowArray, updated : false, new_record : true, send : true}
                          }
                        }

                      },[]).filter((item) => !!item && item.send);

              const done = []

                if(newData.length > 0){

                  MessageBox.confirm('Are you sure you want to synchronize the categories? Item master will be affected and this will take some time.', 'SYNCHRONIZE CATEGORIES', {
                  confirmButtonText: 'YES',
                  cancelButtonText: 'NO',
                  type: 'warning'
                  }).then(async () => {

                    this.formLoading = true
                    await Promise.all(newData.map(async (item) => {
                      try {
                        this.formLoading = true
                        const columnResponse = 7;
                        this.spreadsheetexport.setValueFromCoords(columnResponse,item.row - 1, "Please wait...", true);
                        const response = await this.createOrUpdate({...item, attr : { 
                          variation_label : null,
                          attributes : [],
                          locale : {
                            arabic : item.ar,
                            poland : null,
                          }
                        }, brands : [16]})
                        done.push({...response.data, success : true, send : false, new_record : false, updated : false})
                        if(response.hasOwnProperty('index')){                               
                          const i = parseInt(response.index) - 1;
                          self.setRowSuccess(i + 1)

                          
                          self.setRowValue(i, {...response.data, message : response.message})
                        
                        }
                      } catch (error) {
                        const errorErrorResponse = error.response.data
                        console.log(errorErrorResponse)
                        if(errorErrorResponse.hasOwnProperty('index')){
                          const i = parseInt(errorErrorResponse.index);
                          self.setRowError(i)
                        } 
                        done.push({...item, index : item.row, success : false, send : false, new_record : false, updated : false})
                      }finally{
                        this.formLoading = false
                        console.log("finaly done")
                        console.log(done)
                      }
                    }))

                  });
                  
                }
        }
      
    },
    async onSubmitForm(){
       const self = this;

       const getJsonData = self.spreadsheetexport.getData();
       console.log("getJsonData");
       console.log(getJsonData);
       const getData = getJsonData.map((item, index) => {
         return {
           id : item[0],
           name : item[1],
           ar : item[2],
           area : item[3],
           status : item[4],
           linked_items : item[5],
           row : index + 1
         }
       });
       console.log("getData");
       console.log(getData);
       
            const isValid = self.$refs.observer.validate();

         
            if(isValid){
              const codeColumnData= getData.filter((item) => {
                return item.name !== ''
              }).map((item)=>{
                return `${item.name}`
              })

              getData.forEach((element, index) => {
                self.setStripe(index + 1)
              })

                const areaColumnData= getData.filter((item) => {
                  return item.area === '' || item.area === null || item.ar === '' || item.ar === null
                }).map((item)=>{
                  return `${item.area}`
                })

            

                if(areaColumnData.length > 0){
                  MessageBox.alert('Arabic and Area columns are required.', 'Error', {
                    type : 'error'
                  })

                  getJsonData.forEach((item, index) => {

                    if(item[3] === '' || item[2] === null){ 
                      self.setRowError(index+1);
                     }else{
                      self.setStripe(index + 1)
                     }
                  })
               
                  return false
                }

                

                let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index && item !== '')
                const duplicateValues = [...new Set(findDuplicates(codeColumnData))]

                  if(duplicateValues.length > 0){
                    MessageBox.alert('Name must be unique', 'Error', {
                      type : 'error'
                    })

                    this.updateStyleOnChange()
                  }else{

                   

                    const existingData = this.localData.map((item) => {
                      return {
                        id : item.id,
                        name : item.name,
                        ar : item.ar,
                        area : item.area,
                        status : item.status,
                        linked_items : item.linked_items
                      }
                    });

                    console.log("existingData");
                    console.log(existingData);

                    const newData = getData.filter((item) => {
                      return item.name !== '' || item.status !== '' || item.area !== '' || item.ar !== ''
                    },[]).map((item) => {
                      const rowArray = item;
                        const existing = existingData.find((element) => element.id === rowArray.id);
                        if(existing){
                          if(existing.name !== rowArray.name || existing.status !== rowArray.status || existing.area !== rowArray.area || existing.ar !== rowArray.ar){
                            return {...rowArray, updated : true, new_record : false, send : true}
                          }else{
                            return {...rowArray, updated : false, new_record : false, send : false}
                          }
                        }else{
                          if(rowArray.name !== null){
                            return {...rowArray, updated : false, new_record : true, send : true}
                          }
                        }

                      },[]).filter((item) => !!item && item.send);




                      console.log("newData");
                      console.log(newData);

                      const done = []

                      if(newData.length > 0){
                        this.formLoading = true
                         await Promise.all(newData.map(async (item) => {
                            try {
                              this.formLoading = true

                              console.log("item");
                              console.log(item);

                              const columnResponse = 7;
                              this.spreadsheetexport.setValueFromCoords(columnResponse,item.row - 1, "Please wait...", true);

                              const response = await this.createOrUpdate({...item, attr : { 
                                variation_label : null,
                                attributes : [],
                                locale : {
                                  arabic : item.ar,
                                  poland : null,
                                }
                              }, brands : [16]})
                              done.push({...response.data, success : true, send : false, new_record : false, updated : false})
                              if(response.hasOwnProperty('index')){                               
                                const i = parseInt(response.index) - 1;
                                self.setRowSuccess(i + 1)
                                self.setRowValue(i, {...response.data, message : response.message})
                              
                              }
                            } catch (error) {
                              const errorErrorResponse = error.response.data
                              console.log(errorErrorResponse)
                              if(errorErrorResponse.hasOwnProperty('index')){
                                const i = parseInt(errorErrorResponse.index);
                                self.setRowError(i)
                              } 
                              done.push({...item, index : item.row, success : false, send : false, new_record : false, updated : false})
                            }finally{
                              this.formLoading = false
                              console.log("finaly done")
                              console.log(done)
                            }
                         }))
                      }


                  }        
            }

            
        },
        setRowSuccess(i){
          this.setRowStyle(i, 'success');
        },
        setRowError(i){
            this.setRowStyle(i, 'error');
        },
        setRowDark(index){
          this.setRowStyle(index, 'dark');
        },
        setRowLigth(index){
          this.setRowStyle(index);
        },

        setRowStyle(index, background = 'light'){
          const columns = ['A', 'B', 'C', 'D', 'E', 'F', 'G','H']
          let backgroundColor = '#ffffff'
          if(background === 'error'){
            backgroundColor = 'pink'
          }else if(background === 'success'){
            backgroundColor = '#04b65d'
          }else if(background === 'dark'){
            backgroundColor = '#f9f9f9'
          }else if(background === 'light'){
            backgroundColor = '#ffffff'
          }else{
            backgroundColor = background
          }
          
          columns.forEach((column) => {
            this.spreadsheetexport.setStyle(`${column}${(index)}`, 'background-color', backgroundColor, true);
          })
        },
        setStripe(index){
          if(index % 2 == 0){
            this.setRowDark(index) 
          }else{
           this.setRowLigth(index)
          }
        },

        setRowValue(i, obj){
            this.spreadsheetexport.setValueFromCoords(0,i, obj.id, true); 
            this.spreadsheetexport.setValueFromCoords(1,i, obj.name, true);
            this.spreadsheetexport.setValueFromCoords(2,i, obj.ar, true); 
            this.spreadsheetexport.setValueFromCoords(3,i, obj.area, true);
            this.spreadsheetexport.setValueFromCoords(4,i, obj.status, true); 
            this.spreadsheetexport.setValueFromCoords(5,i, obj.linked_items, true);
            this.spreadsheetexport.setValueFromCoords(7,i, obj.message, true);
        },

        createPopupTable(exportTableOptions){
            console.log(exportTableOptions)
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
           
        },
        reCreatePopupTable(exportTableOptions){

            // exportTableOptions.data = this.areaData


            console.log("before assign")
            console.log(exportTableOptions)
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
           
        },

        async recreateSpreadsheet(){
             this.spreadsheetexport.destroy();

            await this.renderTable();
        },
        fnExportXls(){
            this.spreadsheetexport.download(this.$refs.spreadsheetexport)
        },
        renderTable(){
            const self = this;


            const buttonColumn = {
    
           /*  createCell: function (cell, value, x, y, instance) {
              cell.innerHTML = ""; 
              const button = document.createElement("button");
              button.innerText = value || "Click Me"; 
              
              cell.appendChild(button);
              return value;
            },
            */
            updateCell: function (cell, value, x, y, instance) {
              cell.innerHTML = ""; 
              const button = document.createElement("button");
              button.type = "button";
              button.classList.add("btn", "btn-elegant");
              button.innerText = value || "Click Me";
              cell.appendChild(button);
            },
            
            openEditor: function (cell, value, x, y, instance) {
              instance.closeEditor(cell, false); 
            },
            closeEditor: function (cell, save) {
             
            },
          
            getValue: function (cell) {
              return cell.innerText; 
            },
            setValue: function (cell, value) {
              cell.innerHTML = ""; 
              const button = document.createElement("button");
              button.innerText = value || "Click Me";
              cell.appendChild(button);
            },
          };
          
            this.exportTableOptions.columns = [

             
                  {
                    type : 'text',
                    width : '100px',
                    align : 'left',
                    name : 'id',
                    title : 'ID',
                    readOnly : true
                  },
                  {
                    type : 'text',
                    align : 'left',
                    name : 'name',
                    title : 'NAME',
                    width : '300px',
                  },
                  {
                    type : 'text',
                    align : 'right',
                    name : 'ar',
                    title : 'ARABIC',
                    width : '300px',
                  },

                  {
                    type: 'dropdown',
                    align : 'left',
                    width: '250px',
                    title:'AREA',
                    name:'area',
                    autocomplete:true,
                    source: self.area,
                    multiple:false,
                    readOnly: false,
                    newOptions: true,
               
                  },
                  { type:'dropdown', align : 'left',width : '200px',name : 'status', title:'STATUS', source:[ {'id':'0', 'name':'Inactive'}, {'id':'1', 'name':'Active'} ] },
                  {
                    type : 'text',
                    align : 'right',
                    name : 'linked_items',
                    title : 'LINKED ITEMS',
                    width : '100px',
                    readOnly: true
                  },
                  { type : 'text', align : 'right', width : '100px', name : 'action', title : 'ACTION', readOnly: true },
                  {
                      type: 'text',
                      width: '340px',
                      align : 'left',
                      title: 'RESPONSE',
                      name: 'message',
                  },

            ];

            
                this.createPopupTable(this.exportTableOptions);
        },



        fnExport(doc){

          window.open(`${this.$route.path}/reports/${doc.id}`, "_blank");
          
        },

        async triggerGetAllRecords(){
          const self = this
          self.formLoading = true
          await this.getAllRecords().then((response) => {
            self.localData = response
          })

          self.spreadsheetexport.setData(self.localData)
          self.formLoading = false
        }

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
      tblKey : function(){
        return 1;
      },
      categoryStatus : function(){
        return {'0':'Inactive', '1':'Active'}
      }
  },

  watch: {
      'dialogIsOpen' : async function(newState) {

       const self = this;
              if(!newState){
               this.spreadsheetexport.destroy();
                this.resetForm();
                this.setAll([]);
                return
              }else{
                  self.exportTableOptions.data = []
                  self.renderTable();
                  await self.triggerGetAllRecords();
              }
        },

  }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #dddddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}


</style>