import { ApiService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const SubCategoryModule = {
    namespaced: true,
    state: {
        loading: false,
        deleteloading: false,
        data: {
            columns: [

                {
                    label: 'ID',
                    field: 'id',
                    sort: 'asc',
                    clickable: true,
                    width: '100px'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    clickable: true,
                }
               

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {name : null,category_id : null},

        formStatus: 'create',
        formRecord: {},
        all : [],
        allLoading : false,

        initialFormObject : {
            id : null,
            name : null,
            slug : null,
            category_id : null,
            attr : { 
              locale : {
                arabic : null,
                poland : null,
              }
            },
            status : true,
            hidden : false
        },


        allSubCategory : [],

    },
    actions: {
        
        resetData({dispatch}, endpoint){
            return new Promise((resolve, reject) => {
                dispatch('asyncGetData', endpoint).then((res) => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },
        async asyncGetData({ state, commit, dispatch }) {
            const { loading, length, page, filters, searchTerm } = state
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('loadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ApiService.get('sub-category', params).then((response) => {
                        commit('setPage', response.data.current_page)
                        commit('setTotal', response.data.total)
                        commit('setData', response.data.data)
                        commit('loadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        //reject(error)
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },



        async storeRecord({ state, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    ApiService.store('sub-category', payload).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updateRecord({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('loadingTrue')
                ApiService.update('sub-category', payload).then((response) => {
                    commit('loadingFalse')
                    resolve(response.data)
                }).catch((error) => {
                    commit('loadingFalse')
                    reject(error)
                })
            })
        },
        updateOrder({ state, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    ApiService.update('sub-category/update-order', payload).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
      
        async getAllRecords({commit}, params) {
            commit('setAllLoading',true)
            return await new Promise((resolve,reject) => {
                ApiService.get('sub-category/filter', params).then((response) => {
                    commit('setAllLoading',false)
                    commit('setAll',response.data)
                    resolve(response.data)
                }).catch(error => {
                    commit('setAllLoading',false)
                    reject(error)
                })
            })
        },

        async createOrUpdate({ commit }, payload) {
            commit('loadingTrue')
            return await new Promise((resolve, reject) => {
                ApiService.store('sub-category/create-or-update', payload).then((response) => {
                    commit('loadingFalse')
                    const responseBody = response.data;
                    resolve(responseBody)
                }).catch((error) => {
                    commit('loadingFalse')
                    reject(error)
                })
            })
        },

        async mergeCategories(_, payload) {
            return await new Promise((resolve, reject) => {
                ApiService.update('sub-category/merge', payload).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        async filterBrandByArea({ dispatch }, params) {
            return await new Promise((resolve) => {
                ApiService.get('sub-category/filter-brand-by-area', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
    },
    mutations: {
        loadingFalse: (state) => {
            state.loading = false
        },
        loadingTrue: (state) => {
            state.loading = true
        },

        setData(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setCode: (state, payload) => {
            state.form.item_code = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },

        setFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {
            state.formRecord = payload
        },
        setAll : (state, payload) => {
            state.all = payload || []
        },
        setAllLoading : (state, payload) => {
            state.allLoading = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        }

    },
    getters: {
        loading: state => state.loading,
        deleteloading: state => state.deleteloading,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        formRecord: state => state.formRecord,
        columns: state => state.data.columns,
        formStatus: state => state.formStatus,
        allRecords: state => state.all,
        allLoading: state => state.allLoading,
        searchTerm: state => state.searchTerm,
        initialFormObject : state => state.initialFormObject
    }
}


